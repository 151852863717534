<template>
    <div id="picture-video">
        <div id="picture-container" v-for="(image,index) in photos" :key="index">
            <img :src="image.src">
            <div id="buttonActions" v-if="image.showOption">
                <div style="width: auto;height: auto;font-size: 35px;color: #de0011;cursor: pointer;"
                     @click="rejectImage(image.id,index)">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                </div>
                <div style="width: auto;height: auto;font-size: 35px;color: #0ed22c;cursor: pointer;"
                     @click="acceptImage(image)">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FotosComponent",
        props: ['encargo'],
        data() {
            return {
                photos: []
            };
        },
        methods: {
            acceptImage(image) {
                image.showOption = false;
            },
            rejectImage: function (id, index) {
                this.photos.splice(index, 1);
                const tokenV = $cookies.get('token');
                this.$axios.delete('/api/photos/' + id, {
                    params: {
                        'encargo': this.encargo
                    }
                }, {headers: {Authorization: "Bearer " + tokenV}})
            },
            clearPhotos(){
                console.log("Estas son las fotos", this.photos)
                this.photos = [];
            }
        },
        mounted() {
            self = this;

            this.$root.$socket.on('posicionEncargo', function (data) {
                //var datos = JSON.parse(data);
                document.getElementById('geoposicion').innerHTML += data;
            });

            this.$root.$socket.on('mostrarFoto', function (data) {
                self.photos.push({
                    id: data['photoId'],
                    src: data['file'],
                    showOption: true
                })
                // document.getElementById('fotos').innerHTML += data;
            });

            this.$root.$socket.on('grabacionTallerParada', function (data) {
                //var datos = JSON.parse(data);
                document.getElementById('fotos').innerHTML += "<div>PARADO</div>";
                $scope.pararGrabacion(false)
            });
        },
    }
</script>

<style scoped>

</style>
