<template>
  <div>
    <div class="google-map" ref="googleMap" id="map"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>

  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
    export default {
        props: {
            mapConfig: {
                type: Object,
            },
            apiKey: {
                type: String
            },
            center:{
                type: Object
            }
        },
        data() {
            return {
                google: null,
                map: null,
                marker: null,
                infoWindow : null,
            }
        },
        mounted () {
            new Loader({
                apiKey: this.apiKey,
                version: "weekly",
                libraries: ["places"]
            })
            .loadCallback(e=>{
                if(e) alert(e)
                this.google = window.google
                this.initializeMap()
            })
            // await googleMapApi.load()
            // this.google = window.google
            // this.initializeMap()
        },
        methods: {
            async initializeMap() {
                const mapContainer = this.$refs.googleMap
                this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
                this.google.maps.event.addListenerOnce(this.map, 'idle', ()=>{
                    mapContainer.classList.add('show-map')
                })
                this.marker = new this.google.maps.Marker({
                    position: this.mapConfig.center,
                    map: this.map,
                })
                let location = await this.getLocationText(this.mapConfig.center.lat, this.mapConfig.center.lng)
                this.infoWindow = new this.google.maps.InfoWindow()
                this.infoWindow.setContent(location)
                this.infoWindow.open(this.map, this.marker);
            },
            async getLocationText(lat, lng) {
                try {
                    let URL = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=es`
                    let res = await axios.get(URL)
                    return `${res.data.countryName}, ${res.data.principalSubdivision}, ${res.data.locality}, ${res.data.postcode}`
                } catch (error) {
                    return 'Hubo un error al momento de obtener la localización'
                }
                
            }
        },
    }
</script>

<style lang="scss">
#map{
    width: 100%;
    height: 50vh;
    opacity: 0;
    transition: opacity 150ms ease-in;
    display: block;
    &.show-map{
        opacity: 1;
    }
    overflow: hidden;
    z-index: 0;
}
</style>