<template>
    <div class="container" id="video">
        <div class="left">
            <h4>ENCARGOS</h4>
            <encargos-pendientes-component></encargos-pendientes-component>
        </div>

        <videoperitacion-component @updateShowIconTakePictureDni="updateShowIconTakePictureDni"></videoperitacion-component>

        <div class="right">
            <h4>USUARIOS CONECTADOS</h4>
            <div id="iconos_control" style="margin-left: 20px;">
                <iconos-control-component :showIconTakePictureDni="showIconTakePictureDni"></iconos-control-component>
            </div>
            <encargos-online-component
                :user_id="user_id"
            ></encargos-online-component>
            <div id="geoposicion"></div>
        </div>
    </div>
</template>

<script>
    import IconosControlComponent from "./IconosControlComponent";
    import EncargosPendientesComponent from "./EncargosPendientesComponent";
    export default {
        name: "VideoComponent",
        components: {EncargosPendientesComponent, IconosControlComponent},
        props: {
            user_id: {
                required: true
            },
        },
        data () {
            return {
                showIconTakePictureDni: true
            }
        },
        methods :{
            updateShowIconTakePictureDni (){
                this.showIconTakePictureDni = false
            }
        }
    }
</script>

<style scoped>

</style>
