<template>
    <ul id="encargos" v-cloak>
        <encargo-component
            v-for="(encargoD, index) in encargos_pendientes"
            :key="index"
            :encargoD="encargoD"></encargo-component>
    </ul>
</template>

<script>
    import EncargoComponent from "./EncargoComponent";

    export default {
        name: "EncargosPendientesComponent",
        components: {EncargoComponent},
        data() {
            return {
                encargos_pendientes: []
            };
        },
        created() {
            this.buscarEncargos()
        },
        methods: {
            buscarEncargos: function() {
                this.$isLogged();
                let token = $cookies.get('token');

                self = this;
                self.encargos_pendientes = [];

                this.$axios.post('/encargos', {
                    area: 'Perito'
                }, { headers: { Authorization: "Bearer " + token }} ).then(function (response) {
                    if (response.data.codigo === 0) {
                        $cookies.set('token', response.data.response.token);

                        self.encargos_pendientes = response.data.response.datos.en_curso;
                    }
                });
            },
            informacionEncargo: function(e) {
                detallesVho.mostrarInfoEncargo(this.encargos_pendientes[e]);
                videoAcciones.mostrarIconos();
            },
            llamarTaller: function (e) {
                detallesVho.llamarTaller(e);
            }
        }
    }
</script>

<style scoped>

</style>
