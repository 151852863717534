<template>
    <div>
        <el-table
            :data="displayData"
            style="width: 100%"
            
            
            :header-cell-style="{ background: '#f8f9fa' }"
            :row-class-name="tableRowClassName">
        >  
            <div slot="append" v-if="displayData.length=='0'">
                <el-row>
                    <el-col class="text-center">
                        <h2>No se encuentran datos</h2>
                    </el-col>
                </el-row>
            </div>
            <el-table-column
                prop='n_orden_servicio'
                label='Orden'
                width="100" 
                sortable
            />
            <!-- <el-table-column
                prop='in_course'
                label='Datos del vehículo'
                width="80"
                :formatter='in_course_formatter'
            /> -->
            <el-table-column
                label='Datos del vehículo'
                width="180"
            >
                <template slot-scope="scope">
                    <div class="table_title">{{ scope.row.id_marca }} {{ scope.row.modelo }}</div>
                    <div class="table_subtitle">{{ scope.row.matricula }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop='fecha_hora_cita'
                label='Fecha de cita'
                width="120"

            />
            <el-table-column
                label='Taller / Compañía'
                width="140"
            >
                <template slot-scope="scope">
                    <div class="table_title">{{ scope.row.nombre }}</div>
                    <div class="table_subtitle">{{ scope.row.id_compania }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop='averia1'
                label='Avería'
                width="180"
            />
            <el-table-column
                label='Estado'
                width="100"
            >
                <template slot-scope="scope">
                    <el-tag :type='( scope.row.finish ) ? "success" : "danger"' effect="plain">
                        <span >
                            {{ ( scope.row.finish ) ? "Cerrado" : "Abierto" }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
                align="right"
                
            >
                <template slot="header" slot-scope="scope">
                    <el-input
                    v-model="search"
                    size="mini"
                    placeholder="Buscar"/>
                </template>
                <template slot-scope="scope">
                    <!-- <el-row>
                        <el-col :span="3"> -->
                            <a :href="`/reports/${scope.row.id}`" class="btn btn-success app-neutral" data-toggle="tooltip" :title="`Acceder al informe ${scope.row.id}`">
                                <span><i class="fa fa-clipboard"></i></span>
                            </a>
                        <!-- </el-col>
                        <el-col :span="3"> -->
                            <a :href="`/api/orders/${scope.row.id}`" class="btn btn-primary app-primary-gradient" data-toggle="tooltip" :title="`Editar el cargo ${scope.row.id}`"
                                v-if=!verifySlug
                            >
                                <span><i class="fa fa-pencil"></i></span>
                            </a>
                        <!-- </el-col>
                        <el-col :span="3"> -->
                            <form :action="`/api/orders/${scope.row.id}`" method="POST" v-if="!verifySlug" @submit="confirmForm">
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="_method" value="DELETE">
                                <button class="btn btn-danger app-secundary-gradient" type="submit" @click="confirm(`¿Estás seguro de que deseas borrar el informe ${scope.row.id}?`)" data-toggle="tooltip" :title="`Borrar el encargo ${scope.row.id}`">
                                    <span><i class="fa fa-trash"></i></span>
                                </button>
                            </form>
                            <form :action="`/api/orders/${scope.row.id}/send-sms-email`" method="POST" v-if="scope.row.send_sms && scope.row.send_email && !verifySlug && scope.row.finish!=1 ">
                                <input type="hidden" name="_token" :value="csrf">
                                <button class="btn btn-warning ap-warning-gradient" :disabled='(scope.row.telefono_de_contacto != null) ? false : true'
                                    data-toggle="tooltip" :title="`Enviar mensaje y correo`"
                                >
                                    <span><i class="fa fa-phone"></i> + <i class="fa fa-envelope"></i></span>
                                </button>
                            </form>
                            <form :action="`/api/orders/${scope.row.id}/send-sms`" method="POST" v-else-if="scope.row.send_sms && scope.row.telefono_de_contacto != null && !verifySlug && scope.row.finish!=1">
                                <input type="hidden" name="_token" :value="csrf">
                                <button class="btn btn-warning ap-warning-gradient" :disabled='(scope.row.telefono_de_contacto != null) ? false : true '
                                    data-toggle="tooltip" :title="`Enviar mensaje`"
                                >
                                    <span><i class="fa fa-phone"></i></span>
                                </button>
                            </form>
                            <form :action="`/api/orders/${scope.row.id}/send-email`" method="POST" v-else-if="scope.row.send_email && !verifySlug && scope.row.finish!=1">
                                <input type="hidden" name="_token" :value="csrf">
                                <button class="btn btn-warning ap-warning-gradient" :disabled='(scope.row.send_email != null) ? false : true '
                                    data-toggle="tooltip" :title="`Enviar correo`"
                                >
                                    <span><i class="fa fa-envelope"></i></span>
                                </button>
                            </form>
                            
                            <button class="btn btn-warning ap-warning-gradient" :disabled='true'
                                v-else-if="scope.row.finish!=1"
                                data-toggle="tooltip" :title="`Enviar sms`"
                            >
                                <span><i class="fa fa-phone"></i></span>
                            </button>
                            
                        <!-- </el-col>
                        <el-col :span="3"> -->
                            
<!--                             
                        </el-col>
                    </el-row> -->
                </template>
            </el-table-column>

        </el-table>

        <!-- <el-divider/> -->
        
        <div style="text-align: center">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>



<script>

export default {
    props: {
        rows: {
            type: Array
        },
        csrf:{
            required: false
        },
        slug:{
            type: String,
        }
    },
    data() {
        return {
            search: '',
            total: 0,
            filtered: [],
            page: 1,
            pageSize: 10,
        }
    },
    computed: {
        displayData() {
             if(this.search == null) return this.rows;
      
            this.filtered = this.rows.filter(data => 
                !this.search || 
                data.matricula?.toLowerCase().includes(this.search.toLowerCase()) ||
                data.n_orden_servicio?.toLowerCase().includes(this.search.toLowerCase()) ||
                ((data.in_course) ? 'En curso' : '-').toLowerCase().includes(this.search.toLowerCase()) ||
                data.id_marca?.toLowerCase().includes(this.search.toLowerCase()) ||
                data.modelo?.toLowerCase().includes(this.search.toLowerCase()) ||
                data.fecha_hora_cita?.toLowerCase().includes(this.search.toLowerCase()) ||
                data.nombre?.toLowerCase().includes(this.search.toLowerCase()) ||
                data.id_compania?.toLowerCase().includes(this.search.toLowerCase()) ||
                data.averia1?.toLowerCase().includes(this.search.toLowerCase())
            
            
            );
            
            this.total = this.filtered.length;

            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
        verifySlug(){
            console.log(this.slug == 'workshop' || this.slug == 'Taller')
            return this.slug == 'workshop' || this.slug == 'Taller'
        }
    },
    methods: {
        in_course_formatter(row, column) {
            return row.in_course ? 'En curso' : '-'
        },
        searching() {
            if (!this.search) {
                this.total = this.rows.length;
                return this.rows;
            }
            this.page = 1;
            return this.rows.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()));
        },
        handleCurrentChange(val) {
            this.page = val;
        },
        tableRowClassName({row, rowIndex}){
            if(row.finish) return 'success-row'
            return ''
        },
        confirmForm(e){
            if(confirm('¿Deseas eliminar la orden?')){
                return true
            }
            e.preventDefault();
        }
    },
}
</script>

<style>
.el-table .success-row {
    background: #f0f9eb;
}
form{
    display: inline;
}
.el-table__empty-block {
    display: none!important;
}
</style>

