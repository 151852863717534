<template>
    <div class="row mt-5 mb-3">
        <div class="col-6 text-left">
            {{ video.substr(video.length - 10) }}.mp4
        </div>
        <div class="col-6 text-right">
            <button
                class="btn btn-primary"
                @click="visible=true"
            >    
                Reproducir vídeo
            </button>
        </div>
        <el-dialog
            :title="`Video del encargo`"
            :visible.sync="visible"
            width="30%"
            :close-on-click-modal='false'
            @closed='closeDialog'
            @close='closeDialog'
        >
            
            <div class="row">
                <div class="col-12">
                        <video 
                            id="video" 
                            ref="video" 
                            :src="video" 
                            crossOrigin="anonymous" 
                            controls
                            @play="play"
                            @pause="pause"
                        ></video>
                    <audio id="audio1">
                        <source type="audio/wav" :src="audio_1">
                    </audio>
                    <audio id="audio2">
                        <source type="audio/wav" :src="audio_2">
                    </audio>
                </div>
            </div>
            <div class="row">
                <div class="col text-right">

                    <el-button 
                        type="success" 
                        icon="el-icon-camera" 
                        circle
                        @click="screenshot"
                        data-toggle="tooltip" :title="`Tomar captura`"
                    />

                </div>
                <div class="col text-center">
        
                    <a
                        id="audio_1"
                        :download="audio_1"
                        :href="audio_1"
                        class="el-button el-button--success is-circle"
                        data-toggle="tooltip" :title="`Descargar audio 1`"
                    >
                        <i class="el-icon-headset"></i>
                    </a>
                                        
                </div>
                <div class="col text-left">

                    <a
                        id="audio_2"
                        :download="audio_2"
                        :href="audio_2"
                        class="el-button el-button--success is-circle"
                        data-toggle="tooltip" :title="`Descargar audio 2`"
                    >
                        <i class="el-icon-headset"></i>
                    </a>
                        
                </div>
            </div>

            <div class="row">
                <div class="col-12" id="preview">

                </div>
            </div>
            

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeDialog">Cerrar</el-button>
            </span>

        </el-dialog>
        
    </div>
</template>

<script>
import Media from '@dongido/vue-viaudio'
import { Loading } from 'element-ui';
    export default {
        components: {
            Media,
        },
        props: {
            url_video: {
                type: String,
                required: true 
            },
            audios:{
                type: Array,
                required: true
            },
            orderid:{
                required: true
            },
            i:{
                type: Number,
                required: true,
                default: 0
            }
        },
        created () {
            this.computedAudios()
        },
        data() {
            return {
                images: [],
                video: this.url_video,
                newPhotos : false,
                visible: false,
                audio_1: '',
                audio_2: '',
            }
        },
        methods: {
            closeDialog() {

                // const canvas = document.getElementsByTagName('canvas')
                // Array.from(canvas).forEach(function (element) {
                    
                //     element.toBlob((blob) => {
                        
                //         url = URL.createObjectURL(blob)

                //         console.log(url)

                //     })

                // });
                // canvas.forEach(element => {
                //     console.log(element)
                // });
                var vid = document.getElementById("video");
                vid.pause()
                this.pause()
                this.visible = false
                this.$emit('close', this.newPhotos, this.images)
            },
            updatePaused(event) {
                this.videoElement = event.target;
                this.paused = event.target.paused;
            },
            play(){
                let audio1 = document.getElementById("audio1");
                let audio2 = document.getElementById("audio2");
                audio1.play();
                audio2.play();
            },
            pause(){
                let audio1 = document.getElementById("audio1");
                let audio2 = document.getElementById("audio2");
                audio1.pause();
                audio2.pause();
            },
            screenshot(){
                let videoElement = document.getElementById('video')
                videoElement.setAttribute('crossOrigin', 'anonymous')
                
                const canvas = document.createElement('canvas');

                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                canvas.style.width = '30%'
                canvas.style.marginTop = '5px'
                canvas.style.marginLeft = '2.5px'
                canvas.style.marginRight = '2.5px'
                canvas.getContext('2d').drawImage(videoElement, 0, 0);

                let b64 = canvas.toDataURL();
                this.uploadImage(b64)
                // let picture = canvas.toDataURL('image/jpeg');
                // console.log(picture)

                document.getElementById('preview').appendChild(canvas)
                
            },
            uploadImage(b64){
                var block = b64.split(";");
                var contentType = block[0].split(":")[1];

                var realData = block[1].split(",")[1];
                let blob = this.b64toBlob(realData, contentType);

                let currentDate = new Date();
                let date = `${currentDate.getFullYear()}${(currentDate.getMonth() + 1)}${currentDate.getDate()}`;
                let time = `${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
                let dateTime = `${date}${time}`;
                let imageData = new FormData();
                imageData.append('file', blob, `${this.orderid}_${dateTime}`);
                imageData.append('request_from', 'web');
                imageData.append('lat', null)
                imageData.append('lon', null)
                let token = localStorage.getItem('token')
                let headers = {
                    'Authorization': `Bearer ${token}`
                }
                let loading = Loading.service(
                    {
                        lock: true,
                        text: 'Tomando captura...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    }
                )
                axios.post('/uploadphoto', imageData, {
                    headers: headers
                }).then(res => {
                    console.log('se subio')
                    console.log(res)
                    this.images.push(res.data.ruta)
                    this.newPhotos = true
                    loading.close()
                }).catch(err=>{
                    console.log(err.response.data)
                    alert('Hubo un error al eliminar la imagen.')
                    loading.close()

                })
            },
            b64toBlob(b64Data, contentType, sliceSize){
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                var byteCharacters = atob(b64Data);
                var byteArrays = [];

                for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    var slice = byteCharacters.slice(offset, offset + sliceSize);

                    var byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                var blob = new Blob(byteArrays, {type: contentType});
                return blob;
            },
            computedAudios(){
                // if(this.i == 0){
                if(this.audios.length > 1){
                    this.audio_1 = this.audios[0]['uri']
                    this.audio_2 = this.audios[1]['uri']
                }
                else if(this.audios.length == 1 ){
                    this.audio_1 = this.audios[0]['uri']
                    this.audio_2 = this.audios[1]['uri']
                }
                // }
            }

        },

    }
</script>

<style lang="scss" scoped>

audio{
    display: none;
}
canvas{
    margin-left: 20px;
    margin-right: 20px;
}

video{
    width: 100%;
}

.el-button--success{
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b;
}
.el-button--primary{
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b;
}

</style>