<template>
    <div class="container-fluid">

        <div class="row my-3">
            <div class="col text-left">
                <a href="/orders"
                    class="btn btn-primary"
                >
                    Regresar
                </a>
            </div>
            <div class="col text-right">
                <a
                    :href="`/downloadPDF/${orderid}`"
                    class="btn btn-primary"
                    target="_blank"
                >    
                    Generar informe en PDF
               </a>
            </div>
        </div>
        <div class="row">
            <div class="col text-left bar mx-2">
                <span>Vídeo</span>
            </div>
        </div>
        <el-divider class="my-2"/>

        <modal-video
            v-for="(video, i) in videos" :key="i"
            :url_video="video.uri"
            :audios="video.audios"
            :i='i'
            :orderid="orderid"
            @close='closeModal'
        />
        <div class="row">
            <div class="col text-left bar mx-2">
                <span>Fotos</span>
            </div>
        </div>
        <el-divider class="my-2"/>
        <div class="row justify-content-between mt-4">
            <div class="col-6">
                <upload-photos
                    :orderid='orderid'
                    :csrf="csrf"
                    @uploaded='closeModal'
                />
            </div>
            <div class="col-6 text-right">
                <a
                    class="btn btn-primary my-3"
                    :href="`/download-all-images/${orderid}`"
                    target="_blank"
                >    
                    Descargar todas las imágenes
               </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 my-2" v-for="photo in photos" :key="photo.id">
                
                <photo
                    :photo="photo"
                />
                 
            </div>
        </div>

        

        
    </div>
</template>

<script>
import ModalVideo from './ModalVideoReport.vue'
import Photo from './Photo.vue'
import UploadPhotos from './UploadPhotos.vue'
    export default {
        components: {
            ModalVideo,
            Photo,
            UploadPhotos
        },
        props: {
            videos: {
                type: Array,
                default: [],
                required: false
            },
            audios: {
                type: Array,
                default: '',
                required: false
            },
            photos: {
                type: Array,
                default: [],
            },
            orderid: {
                type: String,
                required: true
            },
            tallerid: {
                type: String,
                required: true
            },
            csrf:{
                required: false
            },
        },
        data() {
            return {
                isModalOpen: false
            }
        },
        methods: {
            closeModal(newPhotos) {
                if(newPhotos){
                    window.location.reload()
                }
                // this.isModalOpen = false
            },
            openModal(){
                this.isModalOpen = true
            },   
        },

    }
</script>

<style lang="scss" scoped>
    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .button {
        padding-top: 20px;
        float: right;
    }

    .image {
        width: 100%;
        display: block;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }
    .el-button--success{
        color: #fff;
        background-color: black;
        border-color: black
    }
    .bar{
        background-color: #007a8b;
        color: white;
        width: 100%;
        height: 30px;
        -webkit-print-color-adjust: exact; 
    }

</style>
