/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');


import element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import CKEditor from 'ckeditor4-vue';
// import VueAnalytics from 'vue-analytics'

import Vue from 'vue';

CKEditor.allowedContent = true;
Vue.use( CKEditor );
Vue.use(element);

// let id = 'G-DVXWYMPCNK'
// Vue.use(VueAnalytics, {
//     // id: '2808206621',
//     id: 'G-DVXWYMPCNK',
//     checkDuplicatedScript: true
// })

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('encargo-component', require('./components/VideoComponents/EncargoComponent').default);
Vue.component('encargo-online-component', require('./components/VideoComponents/EncargoOnlineComponent').default);
Vue.component('encargos-online-component', require('./components/VideoComponents/EncargosOnlineComponent').default);
Vue.component('encargos-pendientes-component', require('./components/VideoComponents/EncargosPendientesComponent').default);
Vue.component('fotos-component', require('./components/VideoComponents/FotosComponent').default);
Vue.component('iconos-control-component', require('./components/VideoComponents/IconosControlComponent').default);
Vue.component('menu-component', require('./components/VideoComponents/MenuComponent.vue').default);
Vue.component('video-component', require('./components/VideoComponents/VideoComponent.vue').default);
Vue.component('videoperitacion-component', require('./components/VideoComponents/VideoperitacionComponent').default);

Vue.component('home-component', require('./components/HomeComponents/HomeComponent').default);

Vue.component('orders-component', require('./components/OrdersComponents/OrdersComponent').default);
Vue.component('orderedit-component', require('./components/OrdersComponents/OrderEditComponent').default);

Vue.component('client-web-component', require('./components/ClientWebComponents/ClientWebComponent.vue').default);


/**
 * Comment
 */
Vue.component('comment-page', require('./components/CommentComponents/Page.vue').default);
Vue.component('data-table', require('./components/DataTable/DataTable.vue').default);

Vue.component('report', require('./components/OrdersComponents/Report').default);

Vue.component('video-peritacion-taller', require('./components/VideoComponents/VideoPeritacionTaller').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const loginFormID = document.getElementById("login-form");
if (loginFormID != null) {
    const login = new Vue({
        el: '#login-form',
        created() {
            Vue.prototype.$axios = axios;
        },
        data: {
            usuario: '',
            password: ''
        },
        methods: {
            processForm: function() {
                let currentObj = this;
                this.$axios.post('/auth_jwt', {
                    usuario: currentObj.usuario,
                    password: currentObj.password,
                    area: 'Perito'
                }).then(function (response) {
                    console.log(response.data);
                    if (response.data.codigo === 0) {
                        localStorage.setItem('token', response.data.response.token);
                        $cookies.set('token', response.data.response.token);
                        window.location.href = 'home';
                    };
                })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
                ;
            }
        }
    });
}

let token = $cookies.get('token');

const headerID = document.getElementById("newHeader");
if (headerID != null) {
    const header = new Vue({
        el: '#newHeader',
        methods: {
            sessionLogout: function() {
                if (confirm('¿Desea realmente cerrar la sesión?') == true) {
                    $cookies.remove('token');
                    localStorage.clear();
                    document.getElementById('logout-form').submit();
                }
            }
        }
    });
}

const id = 0;
const area = "Perito";
const cia = 0;
const nombre = "";

Vue.prototype.$getToken = function() {
    if (!localStorage.getItem('token')) {
        this.$axios.get('/api/token', {
        }).then(function (response) {
            console.log(response.data);
            if (response.data.codigo === 0) {
                localStorage.setItem('token', response.data.response.token);
                localStorage.setItem('user_id', response.data.response.user_id);
                $cookies.set('token', response.data.response.token);
                $cookies.set('user_id', response.data.response.user_id);
            };
        }).catch(function (error) {
            this.output = error;
        });
    }
}

Vue.prototype.$isLogged = function() {
    let token = $cookies.get('token');
//    if (token == null) {
//        window.location.href = '';
//    }
    const decoded = jwt_decode(token);
    const expire = new Date(0).setUTCSeconds(decoded.exp);
//    if ((new Date) > expire) {
//        window.location.href = '';
//    }
    localStorage.setItem('nombre', decoded.data.nombre);
};

Vue.filter('uppercase', function (value) {
    if (value != null) {
        return value.toUpperCase()
    }
    return null;
});

const appID = document.getElementById("app");
if (appID != null) {
    const app = new Vue({
        el: '#app',
        data() {
            return {
            };
        },
        mounted () {
            if(!localStorage.getItem('token')){
                this.$getToken();
            };
        },
        created() {
            Vue.prototype.$axios = axios;

            this.$isLogged();
            let id = localStorage.getItem('user_id');
            let cia = localStorage.getItem('user_id')

            // Vue.prototype.$socket = io('https://www.avatarexpert.es:55555', {query: 'auth_token=' + token + '&id=' + id + '&area=' + area + '&cia=' + cia});
            // Vue.prototype.$socket = io('http://localhost:55555', {query: 'auth_token=' + token + '&id=' + id + '&area=' + area + '&cia=' + cia});
            Vue.prototype.$socket = io('https://peritajes.invarat.mx:55555', {query: 'auth_token=' + token + '&id=' + id + '&area=' + area + '&cia=' + cia});
            // Vue.prototype.VideoTwilio = Twilio.Video;
        }
    });
}

const homeappID = document.getElementById("homeapp");
if (homeappID != null) {
    const app = new Vue({
        el: '#homeapp',
        mounted() {
            console.log("HOME MOUNTED!");
            // this.$ga.enable();
            this.$ga.page({
                page: '/home',
                title: 'Homepage',
                location: window.location.href
            })
            console.log('Mandando data a google analitycs')
        },
        data() {
            return {
            };
        },
        created() {
            //this.$isLogged();
            Vue.prototype.$axios = axios;
            this.$getToken();
        }
    });
}

const ordersappID = document.getElementById("ordersapp");
if (ordersappID != null) {
    const app = new Vue({
        el: '#ordersapp',
        mounted() {
            console.log("ORDERS MOUNTED!");
        },
        data() {
            return {
            };
        },
        created() {
            this.$isLogged();
            Vue.prototype.$axios = axios;
        }
    });
}

const orderEditID = document.getElementById("ordereditapp");
if (orderEditID != null) {
    const app = new Vue({
        el: "#ordereditapp",
        mounted() {
            console.log("ORDER EDIT MOUNTED!");
        },
        data(){
            return {
            }
        },
        created() {
            this.$isLogged();
            Vue.prototype.$axios = axios;
        }
    });
}

// const idClientWeb = 18;
const areaClientWeb = "Taller";
// const tokenClient = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTcwNDc4OTEsImV4cCI6MTYzMjU5OTg5MSwiZGF0YSI6eyJpZCI6MjYsImFyZWEiOiJQZXJpdG8iLCJub21icmUiOiJhZG1pbiJ9fQ.O6LwDCqziPm0vFlSH4c5kBRRtFYUPcuNOPsF3F0ZsEc";

const clientWebApp = document.getElementById("clientWebApp");
if(clientWebApp != null) {
    const app = new Vue({
        el: "#clientWebApp",
        mounted() {
            console.log('CLIENT WEB MOUNTED!')
            // this.$ga.enable();
            // this.$ga.event('device', 'action', 'test', 123)
            // this.$ga.page({
            //         page: '/',
            //         title: 'client page',
            //         location: window.location.href
            //     })
        },
        data() {
            return {}
        },
        created() {
            // this.$isLogged();
            Vue.prototype.$axios = axios;
            // Vue.prototype.$socketClientWeb = io('https://peritajes.invarat.mx:55555', {query: 'auth_token=' + tokenClient + '&id=' + idClientWeb + '&area=' + areaClientWeb});
            // Vue.prototype.$socketClientWeb = io('http://localhost:55555', {query: 'auth_token=' + tokenClient + '&id=' + idClientWeb + '&area=' + areaClientWeb});
        }
    });
}
