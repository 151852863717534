<template>
    <div class="middle" id="videoperitacion">
        <div class="row" id="datos_vehiculo_header">
            <div class="col-6">
                <h4 id="titulo_peritacion" v-cloak>{{encargo_seleccionado_taller || 'VIDEO PERITACIÓN' | uppercase}}</h4>
                <div id="detalles_vehiculo" v-cloak>
                    {{encargo_seleccionado_marca | uppercase}} {{encargo_seleccionado_modelo | uppercase}} {{encargo_seleccionado_matricula |uppercase}}
                </div>
            </div>
            <div class="col-6">
                
                <location
                    v-if="userLocation"
                    :lat=" userLocation.lat"
                    :lon="userLocation.lon"
                />
            </div>
        </div>
            

        <hr>
        <div id="multimedia">
            <div class="col-12">
                <h2>
                    {{ helperText }}
                </h2>
            </div>
            <div id="videoReceptor"></div>
            <div id="videoEmisor"></div>
            <div id="audio"></div>
            
        </div>
        <div id="fotos">
            <div id="pictures-dni">
                <div id="picture-container" v-for="(image,index) in dataImages" :key="index">
                    <img :src="image.src">
                    <div id="buttonActions" v-if="image.showOption">
                        <div style="width: auto;height: auto;font-size: 35px;color: #de0011;cursor: pointer;" @click="rejectImage(image,index)">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </div>
                        <div style="width: auto;height: auto;font-size: 35px;color: #0ed22c;cursor: pointer;" @click="acceptImage(image)">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <fotos-component :encargo="encargo_seleccionado_idEncargo"></fotos-component>
        </div>
    </div>
</template>

<script>   
    import Location from './Location.vue'
    import {connect, createLocalVideoTrack} from 'twilio-video';

    export default {
        name: "VideoperitacionComponent",
        components: {
            Location,
        },
        data() {
            return {
                localTrack: false,
                encargo_seleccionado: false,
                enlace_idEncargo: "",
                encargo_seleccionado_idEncargo: "",
                encargo_seleccionado_taller: "",
                encargo_seleccionado_id_taller: 0,
                encargo_seleccionado_marca: "",
                encargo_seleccionado_modelo: "",
                encargo_seleccionado_matricula: "",
                room: null,
                local: null,
                dataImages: [],
                amountAccept: 0,
                helperText:'',
                userLocation: null,
            }
        },
        beforeDestroy() {
            this.desconectar();
            this.$socket.emit('disconnect')
        },
        mounted() {
            const VueThis = this;
            this.$socket.on('dniFoto', function (data) {
                VueThis.dataImages.push({
                    countImage: data['countImage'],
                    src: data['foto'],
                    showOption: true
                });
            });
            this.$socket.on('change_orientation', function (data) {
                console.log('change orientation')
                console.log(data)
            });
            this.$socket.on('get_location', (data) => {
                console.log('recibiendo location')
                console.log(data)
                this.userLocation = {
                    lat : data.lat,
                    lon: data.lon,
                }
            });
            this.$socket.on('user_connecting', (data) => {
                this.iniciarVideo()
            })
            // this.$socket.on('user_disconected', (data) => {
            //     console.log('user_disconected')
            //     console.log(data)
            //     console.log(this.encargo_seleccionado_idEncargo == data.id_encargo)
            //     if(data.id_encargo == this.encargo_seleccionado_idEncargo){
            //         this.disconected();
            //     }
            // })
        },
        methods: {
            mostrarInfoEncargo: function (encargo) {
                this.enlace_idEncargo = encargo.id_encargo;
                this.encargo_seleccionado = true;
                this.encargo_seleccionado_idEncargo = encargo.id_encargo;
                this.encargo_seleccionado_taller = encargo.taller;
                this.encargo_seleccionado_id_taller = encargo.id_taller;
                this.encargo_seleccionado_marca = encargo.marca || encargo.id_marca;
                this.encargo_seleccionado_modelo = encargo.modelo;
                this.encargo_seleccionado_matricula = encargo.matricula;
                this.$socket.emit('solicitarUnirseAlperito',{ id_encargo: this.encargo_seleccionado_idEncargo, id_taller: this.encargo_seleccionado_id_taller, id_compania: this.encargo_seleccionado_idEncargo});
                //this.iniciarVideo();
                //this.llamarTaller();
            },
            llamarTaller: function (encargo) {
                this.mostrarInfoEncargo(encargo);
                document.getElementById('fotos').innerHTML += "<div>ENVIAR NOTIFICACION</div>";
            },
            reconectar(){
                this.iniciarVideo();
                this.$socket.emit('solicitarUnirseAlperito',{ id_encargo: this.encargo_seleccionado_idEncargo, id_taller: this.encargo_seleccionado_id_taller, id_compania: this.encargo_seleccionado_idEncargo});
            },
            iniciarVideo: function () {
                this.helperText = ''
                let tokenV = $cookies.get('token');

                const VueThis = this;
                const axios = this.$axios;
                // const VideoTwilio = this.$root.VideoTwilio;

                axios.post('/twilio_token', {
                    area: 'Perito',
                    token: tokenV,
                    room: VueThis.encargo_seleccionado_idEncargo,
                    identity: localStorage.nombre
                }, { headers: { Authorization: "Bearer " + tokenV }} ).then(function (response) {
                    if (response.data !== 1 && response.data.codigo == 0 && response.data.token !== 1) {
                        let connectOptions = {
                            name:  self.encargo_seleccionado_idEncargo,
                            //logLevel: 'debug',
                            audio: true,
                            video: false,
                            // bandwidthProfile: {
                            //     video: {
                            //         maxSubscriptionBitrate: 2500000,
                            //         maxTracks: 0,
                            //         trackSwitchOffMode: 'predicted',
                            //         dominantSpeakerPriority: 'high',
                            //         renderDimensions: {
                            //             // high: {height:1080, width:1920},
                            //             // standard: {height:720, width:1280},
                            //             high: {height:176, width:144},
                            //             standard: {height:176, width:144},
                            //             low: {height:176, width:144}
                            //         }
                            //     }
                            // },
                            preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
                            // networkQuality: {local:1, remote: 1}
                        };
                        

                        connect(response.data.token, connectOptions).then(room => {
                            VueThis.$socket.emit('solicitarPrincipioEmision', { id_encargo: VueThis.encargo_seleccionado_idEncargo, id_taller: VueThis.encargo_seleccionado_id_taller, id_compania: VueThis.encargo_seleccionado_idEncargo});
                            console.log('Connected to Room "%s" room_sid "%s"', room.name, room.sid);
                            VueThis.room = room;
                            const localParticipant = room.localParticipant;
                            axios.get('/room_info', {
                                params: {
                                    room_sid: room.sid,
                                    room_name: room.name,
                                    participant_sid_perito: localParticipant.sid
                                }, headers: {
                                    Authorization: "Bearer " + tokenV
                                }
                            });
                            console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);
                            // Log any Participants already connected to the Room
                            room.participants.forEach(participant => {
                                console.log(`Participant "${participant.identity}" is connected to the Room`);

                                participant.tracks.forEach(publication => {
                                    console.log('tracks')
                                    if (publication.isSubscribed) {
                                        console.log('Publish track')
                                        
                                        const track = publication.track;
                                        document.getElementById('videoReceptor').appendChild(track.attach());
                                    }
                                });
                                participant.on('trackSubscribed', track => {
                                    console.log('track subscribed')
                                    document.getElementById('videoReceptor').appendChild(track.attach());
                                });
                            });

                            // Log new Participants as they connect to the Room
                            room.once('participantConnected', participant => {
                                console.log(`Participant "${participant.identity}" has connected to the Room`);

                                participant.tracks.forEach(publication => {
                                    if (publication.isSubscribed) {
                                        const track = publication.track;
                                        document.getElementById('videoReceptor').appendChild(track.attach());
                                    }
                                });

                                participant.on('trackSubscribed', track => {
                                    if(track.kind == "video"){
                                        console.log('el cliente acaba de crear un track de video')
                                        const selector = document.getElementById('videoReceptor');
                                        while (selector.firstChild) {
                                            selector.removeChild(selector.lastChild);
                                        }
                                        selector.appendChild(track.attach());
                                    }

                                    if (track.kind === 'audio') {
                                        console.log('el cliente acaba de crear un track de audio')
                                        var div = document.getElementById('audio');
                                        div.appendChild(track.attach())
                                    };
                                });
                            });

                            // Log Participants as they disconnect from the Room
                            room.once('participantDisconnected', participant => {
                                console.log(`Participant "${participant.identity}" hasdisconnected from the Room`);
                                VueThis.disconected()
                            });

                            room.on('disconnected', room => {
                                // Detach the local media elements
                                room.localParticipant.tracks.forEach(publication => {
                                    publication.track.stop();
                                    const attachedElements = publication.track.detach();
                                    attachedElements.forEach(element => element.remove());

                                });
                            });

                            // if(!VueThis.localTrack) {
                            //     VideoTwilio.createLocalVideoTrack().then(track => {
                            //         VueThis.local = track;
                            //         let localMediaContainer = document.getElementById('videoEmisor');
                            //         localMediaContainer.appendChild(track.attach());
                            //         VueThis.localTrack = true;
                            //     });
                            // }
                        }).catch(e =>{
                            alert(e.message)
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            },
            desconectar: function () {
                try {
                    this.room.localParticipant.tracks.forEach(publication => {
                        publication.track.stop();
                        publication.unpublish();
                        publication.track.detach().forEach((detachedElement) => {
                            detachedElement.remove();
                        });
                    });
                    // this.room.participants.forEach(remoteParticipant => {
                    //     remoteParticipant.disconnect()
                    // })
                    this.room.disconnect();
                } catch (error) {
                    console.log(error)
                }

                
                let selector = document.getElementById('videoEmisor');
                while (selector.firstChild) {
                    selector.removeChild(selector.lastChild);
                }
                selector = document.getElementById('videoReceptor');
                while (selector.firstChild) {
                    selector.removeChild(selector.lastChild);
                }
                const videoAcciones = this.$root.$children[0].$children[2];
                videoAcciones.ocultarIconos();

                this.closeOrder();
                this.resetData()
            },
            acceptImage: function (image) {
                this.$socket.emit('acceptPhoto', {
                    id_encargo: this.encargo_seleccionado_idEncargo,
                    id_taller: this.encargo_seleccionado_id_taller,
                    id_compania: 0,
                    countImage: image.countImage,
                    foto: image.src
                });
                image.showOption = false;
                this.amountAccept += 1;
                if (this.amountAccept === 3) {
                    for (let i = this.dataImages.length - 1; i >= 0; i--) {;
                        this.dataImages[i].showOption && this.rejectImage(this.dataImages[i], i)
                    }
                    this.$emit('updateShowIconTakePictureDni')
                }
            },
            rejectImage: function (image, index) {
                // this.$socket.emit('rejectPhoto', {
                //     id_encargo: this.encargo_seleccionado_idEncargo,
                //     id_taller: this.encargo_seleccionado_id_taller.toString(),
                //     id_compania: 0,
                //     countImage: image.countImage,
                //     foto: image.src
                // });
                this.dataImages.splice(index, 1);
            },
            closeOrder(){
                this.clearPhotos()
                axios.post(`/cerrar-encargo/${this.encargo_seleccionado_idEncargo}`)
                .then(res => {
                    alert(res.data.message);
                    //this.clearPhotos()
                    //console.log(res)
                })
                .catch(err => {
                    alert(res.data.message)
                    console.error(err); 
                })
            },
            disconected(){
                this.$message({
                    message: 'El usuario se ha desconectado.',
                    type: 'warning',
                    duration: 5000,
                });
                this.room.localParticipant.tracks.forEach(publication => {
                    publication.track.stop();
                    publication.unpublish();
                    publication.track.detach().forEach((detachedElement) => {
                        detachedElement.remove();
                    });
                });
                try {
                    this.roomTwilio.participants.forEach(remoteParticipant => {
                        remoteParticipant.disconnect()
                    })
                } catch (error) {
                    
                }
                this.room.disconnect();
                this.encargo_seleccionado_idEncargo = '';
                this.helperText = 'El usuario se ha desconectado'
                setInterval(() =>{
                    this.helperText = "";
                }, 5000)
                const videoAcciones = this.$root.$children[0].$children[2]
                videoAcciones.ocultarIconos()
                this.resetData()
                const selector = document.getElementById('videoReceptor');
                while (selector.firstChild) {
                    selector.removeChild(selector.lastChild);
                }
            },
            resetData(){
                this.localTrack= false
                this.encargo_seleccionado= false
                this.enlace_idEncargo= ""
                this.encargo_seleccionado_idEncargo= ""
                this.encargo_seleccionado_taller= ""
                this.encargo_seleccionado_id_taller= 0
                this.encargo_seleccionado_marca= ""
                this.encargo_seleccionado_modelo= ""
                this.encargo_seleccionado_matricula= ""
                this.userLocation = null
                this.room = null
                this.local = null
            },
            clearPhotos(){
                console.log("Entro al clear", this.$root.$children[0].$children[1].$children[0])
                const _photos = this.$root.$children[0].$children[1].$children[0];
                _photos.clearPhotos();
                 
            }
        }
    }
</script>

<style scoped>

</style>
