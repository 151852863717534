<template>
    <li class="encargo_online" @click="enlazarStream()">
        <div class="nombre_taller">{{encargoOnline.taller}}</div>
        <div class="detalles">{{encargoOnline.marca}} {{encargoOnline.modelo}} - {{encargoOnline.matricula}}</div>
    </li>
</template>

<script>
    export default {
        name: "EncargoOnlineComponent",
        props: ["encargoOnline"],
        data() {
            return {
            };
        },
        methods: {
            enlazarStream: function (e) {
                const detallesVho = this.$root.$children[0].$children[1];
                const videoAcciones = this.$root.$children[0].$children[2];
                console.log(detallesVho.encargo_seleccionado)
                if(!detallesVho.encargo_seleccionado){
                    console.log('EPA entró')
                    detallesVho.mostrarInfoEncargo(this.encargoOnline);
                    // detallesVho.iniciarVideo();
                    videoAcciones.mostrarIconos();
                    detallesVho.encargo_seleccionado_marca = this.encargoOnline.marca;
                }
                else{
                    alert('te encuentras en una videoperitacion, por favor termina una para empezar otra')
                }
            }
        },
    }
</script>

<style scoped>

</style>
