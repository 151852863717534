<template>
    <el-card :body-style="{ padding: '0px' }" >
        <img 
            :src="photo.pie" 
            class="image" 
            v-if="photo.pie !=''"
            style='background: black; height: 100%; width: 100%; overflow: hidden; object-fit: contain;'
        >
        <img 
            :src="photo.path" 
            class="image" 
            v-else
            style='background: black; height: 100%; width: 100%; overflow: hidden; object-fit: contain;'   
        >
        <div style="padding: 14px;">
            <span>Ubicación: {{ photo.literal }} {{ (photo.tipo_foto == 'externa' ? '-externa' : '') }}</span>
            <div class="bottom clearfix">
                <time class="time">Fecha de captura: {{ formatDate(photo.created_at) }}</time>
                <div class="bottom clearfix">
                    <a 
                        :href="`/downloadImage/${photo.id}`" 
                        alt="Descargar" 
                        class="button" 
                        style="float: left"
                        target="_blank"
                    > <i class="fa fa-download"> </i></a>

                    <el-popconfirm
                        title="Estas seguro que deseas eliminar la foto?"
                        style="float: right"
                        confirm-button-text='Sí'
                        cancel-button-text='No'
                        @confirm='remove'
                    >
                        <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
                    </el-popconfirm>
                </div>
                
            </div>
        </div>
    </el-card>
</template>

<script>
import { Loading } from 'element-ui';

    export default {
        props: {
            photo: {
                type: Object,
            },
        },
        methods: {
            formatDate(date){
                date = new Date(date)
                let curr_date = date.getDate()
                let curr_month = date.getMonth()+1
                let curr_year = date.getFullYear()
                let curr_hour = date.getHours()
                let curr_min = date.getMinutes()
                return `${curr_date}/${curr_month}/${curr_year} ${curr_hour}:${curr_min}`
            },
            remove(){
                let token = localStorage.getItem('token')
                let headers = {
                    'Authorization': `Bearer ${token}`
                }
                let loading = Loading.service(
                    {
                        lock: true,
                        text: 'Eliminando foto...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    }
                )
                axios.delete(`/photos/${this.photo.id}`, {
                    headers: headers
                })
                .then(res => {
                    window.location.reload()
                    loading.close()
                })
                .catch(err => {
                    console.error(err); 
                    alert(err.response.data.message)
                    loading.close()
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>