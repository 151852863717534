<template>
  <el-container style="background-color: #f9f2f2; height: 100%" class="mt-4">
    <el-header>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="24" align="center" class="mt-2">
          <div
            :style="{
              'background-color': configuration
                ? configuration.color
                : '#D5E7E7',
            }"
            style="height: 35px; border-radius: 10px"
          >
            <span
              style="
                color: #706161;
                text-align: center;
                font-family: Garamond, serif;
                font-size: 18px;
                font-weight: bolder;
              "
              class="font-weight-black"
              >{{ on_play_text }}</span
            >
          </div>
        </el-col>
      </el-row>
    </el-header>

    <el-row type="flex" class="ml-4" justify="center">
      <el-col :span="24">
        <div
          style="
            border-radius: 10px;
            background-color: #eee5e5;
            width: 94%;
            margin: 0;
          "
          v-if="!on_play"
        >
          <el-row>
            <el-col class="ml-2">
              <i class="el-icon-s-claim" mr-1 style="color: #007a8b"></i>
              <span class="font-weight-bold" style="color: #706161">
                Número de Expediente
              </span>
            </el-col>
          </el-row>
          <el-divider class="mt-n4" style="color: #fff"></el-divider>
          <el-row>
            <el-col align="end" class="pr-1">
              <span class="font-weight-bold" style="color: #706161">
                {{ encargo_pendiente.numero_expediente }}
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col class="ml-2">
              <i class="el-icon-bank-card" mr-1 style="color: #007a8b"></i>
              <span class="font-weight-bold" style="color: #706161">
                Matrícula
              </span>
            </el-col>
          </el-row>
          <el-divider class="mt-n4" style="color: #fff"></el-divider>
          <el-row>
            <el-col align="end" class="pr-1">
              <span class="font-weight-bold" style="color: #706161">
                {{ encargo_pendiente.matricula }}
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col class="ml-2">
              <i class="el-icon-bangzhu" mr-1 style="color: #007a8b"></i>
              <span class="font-weight-bold" style="color: #706161">
                Marca
              </span>
            </el-col>
          </el-row>
          <el-divider class="mt-n4" style="color: #fff"></el-divider>
          <el-row>
            <el-col align="end" class="pr-1">
              <span class="font-weight-bold" style="color: #706161">
                {{ encargo_pendiente.marca }}
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col class="ml-2">
              <i class="el-icon-check" mr-1 style="color: #007a8b"></i>
              <span class="font-weight-bold" style="color: #706161">
                Modelo
              </span>
            </el-col>
          </el-row>
          <el-divider class="mt-n4" style="color: #fff"></el-divider>
          <el-row>
            <el-col align="end" class="pr-1">
              <span class="font-weight-bold" style="color: #706161">
                {{ encargo_pendiente.modelo.slice(0, 20) }}
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col class="ml-2">
              <i class="el-icon-date" mr-1 style="color: #007a8b"></i>
              <span class="font-weight-bold" style="color: #706161">
                Fecha y hora cita
              </span>
            </el-col>
          </el-row>
          <el-divider class="mt-n4" style="color: #fff"></el-divider>
          
          <el-row>
            <el-col align="end" class="pr-1">
              <span class="font-weight-bold" style="color: #706161">
                {{ encargo_pendiente.fecha_peritar }}
              </span>
            </el-col>
          </el-row>
          <el-divider></el-divider>          
        </div>
        <el-row class="mb-2">
            <el-col class="text-center mt-2">
              <i
                class="el-icon-loading"
                style="color: #007a8b"
                v-if="show_loading"
              ></i>
              <p
                class="h3"
                style="color: #007a8b; font-family: Garamond, serif"
                v-if="show_message_client"
              >
                {{ message_client }}
              </p>
              <el-button
                icon="el-icon-caret-right"
                circle
                style="color: #fff; background-color: #4fd1d9"
                v-if="peritoIsConnected"
                @click="iniciarVideo"
              ></el-button>
            </el-col>
          </el-row>
        <el-row class="row text-center">
              <el-col>
                  <span style="color: #007a8b; font-family: Garamond, serif">{{ helperText }}</span>
              </el-col>
              <el-col>
                <div id="my-video-chat-window"></div>  
              </el-col>
              <el-col v-if="uploadingPhoto">
                  <span style="color: #007a8b; font-family: Garamond, serif">
                      Se está subiendo una foto, espere un momento...
                  </span>
                  
              </el-col>
              
          </el-row>
        <div id="audio"></div>
      </el-col>
    </el-row>

    <el-dialog
      title="Importante"
      :visible.sync="helperDialog"
      width="70%"
      center
    >
      <div slot="title">
        <i class="el-icon-message-solid" mr-1 style="color: #59f3c5"></i>
        <span style="color: #007a8b">Importante</span>
      </div>
      <div class="row text-center">
        <div class="col">
          <p class="break">
            Para iniciar la video peritación y se pueda establecer la llamada,
            debes otorgar permisos de la cámara, micrófono y ubicación.
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="helperDialog = false"
          color="#4FD1D9"
          style="color: #706161; font-weight: bolder; background-color:#4FD1D9"
          >Cerrar</el-button
        >
      </span>
    </el-dialog>
  </el-container>
  <!--<div class="container-fluid" id="clientWeb">
        <div class="row mt-4 px-2" v-if="!fullScreen">
            <div 
                id="content_title" 
                class="content_title col-11 px-2"
                :style="{ 'background': (configuration) ? configuration.color : '#007a8b' }"
                style="display: block; text-align: left; position: relative; width: 100%;"
            >
                <p>Encargo en curso</p>
            </div>
            <div class="row">

                <div class="col-6">
                    <span>
                        Número de expediente
                    </span>
                </div>

                <div class="col-6 text-right align-content-center">
                    <span>
                        {{ encargo_pendiente.numero_expediente }}
                    </span>
                </div>
                

            
                <div class="col-6">
                    <span>
                        Matrícula
                    </span>
                </div>
                <div class="col-6 text-right">
                    <span>
                        {{ encargo_pendiente.matricula }}
                    </span>
                </div>
        
                <div class="col-6">
                    <span>
                        Marca
                    </span>
                </div>
                
                <div class="col-6 text-right">
                    <span>
                        {{ encargo_pendiente.marca }}
                    </span>
                </div>
            
                <div class="col-6">
                    <span>
                        Modelo
                    </span>
                </div>
                <div class="col-6 text-right">
                    <span>
                        {{ encargo_pendiente.modelo.slice(0, 20) }}
                    </span>
                </div>

                <div class="col-6">
                    <span>
                        Fecha de cita
                    </span> 
                </div>
                <div class="col-6 text-right">
                    <span>
                        {{ encargo_pendiente.fecha_peritar }}
                    </span>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-2" v-if="!inVideoPeritacion && isZaragoza">

                <button class="btn btn-primary" @click="iniciarVideo" v-if="peritoIsConnected && orientation == 'landscape'">
                    Iniciar videoperitación
                </button>
                <span class="badge badge-info" v-else>
                    {{ message_client }}
                </span>
            </div>
            <div class="col-12 text-center mt-2" v-else-if="!inVideoPeritacion && !isZaragoza">

                <button class="btn btn-primary" @click="iniciarVideo" v-if="peritoIsConnected && orientation">
                    Iniciar videoperitación
                </button>
                <span class="badge badge-info" v-show="!peritoIsConnected">
                    {{ message_client }}
                </span>
            </div>
        </div>

        <div class="row text-center">
            

            <div class="col-12">
                <h2>
                    {{ helperText }}
                </h2>
            </div>
            <div class="col-12">
                <div id="my-video-chat-window"></div>
            </div>
            <div class="col-12" v-if="uploadingPhoto">
                Se está subiendo una foto, espere un momento...
            </div>
        </div>
        
        <div id="audio"></div>

        <el-dialog
            title="Importante"
            :visible.sync="helperDialog"
            width="70%"
            center
            >
            <div class="row text-center">
                <div class="col">
                    <p class="break">
                        Para iniciar la video peritación y se pueda establecer la llamada, debes otorgar permisos de la cámara, micrófono y ubicación, la misma se mostrará en la parte superior izquierda de la pantalla.
                    </p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="helperDialog = false">Cerrar</el-button>
            </span>
        </el-dialog>
        
    </div>-->
</template>

<style>
.btn-dni-photo {
  width: 140px;
}
</style>

<script>
// import Webcam from 'webcam-easy';
import {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  isSupported,
  createLocalAudioTrack,
  LocalAudioTrack,
} from "twilio-video";

export default {
  name: "ClientWebComponent",
  componenets: {},
  props: {
    encargo_id: {
      required: true,
    },
    company: {
      type: String,
    },
    expert: {
      type: String,
    },
    configuration: {
      required: false,
    },
  },
  data() {
    return {
      camera: "",
      socket: null,
      encargo_pendiente: {
        numero_expediente: "",
        matricula: "",
        marca: "",
        modelo: "",
        fecha_peritar: "",
        id_taller: "",
        id_perito: "",
      },
      id_perito: "",
      roomName: null,
      identity: "Cliente_" + Math.floor(Math.random() * 101),
      accessToken: "",
      idEncargo: null,
      marca: null,
      modelo: null,
      matricula: null,
      twilioToken: "",
      countPhoto: 0,
      acceptedPhotos: 0,
      webcam: null,
      peritoIsConnected: false,
      inVideoPeritacion: false,
      tracksTwilio: null,
      roomTwilio: null,
      facingMode: "user",
      devices: [],
      helperText: "",
      uploadingPhoto: false,
      position: {
        latitude: null,
        longitude: null,
      },
      fullScreen: false,
      isIOS:
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document),
      orientation: "portrait",
      isZaragoza: false,
      helperDialog: true,
      $socketClientWeb: null,
      message_client: "Esperando perito...",
      on_play: false,
      on_play_text: "Encargo en curso",
      show_loading: true,
      show_message_client: true
    };
  },
  beforeDestroy() {
    this.roomTwilio.disconnect();
    this.$socketClientWeb.emit("disconnect");
  },
  created() {
    window.addEventListener("beforeunload", () => {
      if (this.roomTwilio) {
        this.roomTwilio.disconnect();
      }
    });
    this.$socketClientWeb = io("https://peritajes.invarat.mx:55555", {
      reconnectionDelayMax:1000,
      query:
        "auth_token=" +
        tokenClient +
        "&id=" +
        idClientWeb +
        "&area=Taller" +
        `&idPerito=${this.expert}` +
        `&idEncargo=${this.encargo_id}`,
    });
  },
  mounted() {
    // this.company = 'zaragosa';
    if (this.company == "Zaragoza") {
      this.isZaragoza = true;
      // this.checkOrientation();
      this.helperText = "Gire el móvil para iniciar la videoperitación";
      window.addEventListener("orientationchange", this.checkOrientation);
    }
    self = this;
    let pathname = window.location.pathname;
    let numeroExpediente = pathname.substring(pathname.lastIndexOf("/") + 1);

    self.$axios
      .post("/auth_jwt", {
        usuario: "demo",
        password: "demo",
        area: "Taller",
      })
      .then(function (response) {
        console.log("Respuesta en el /auth_jwt:",response);
        self.accessToken = response.data.response.token;
        self.buscarEncargos(numeroExpediente);
      });
    self.$socketClientWeb.on("pararGrabacion", function (data) {
      console.log("pararGrabacion", data);
    });

    self.$socketClientWeb.on("encenderFlash", function (data) {
      console.log("encenderFlash", data);
    });

    self.$socketClientWeb.on("unirseAlPerito", function (data) {
      self.peritoIsConnected = true;
      self.message_client = "Iniciar Videoperitación";
      self.show_loading = false;
    });

    self.$socketClientWeb.on("tomarFotoDni", function (data) {
      console.log("tomarFotoDni");

      self.takeImage();
    });

    self.$socketClientWeb.on("tomarFoto", (data) => {
      this.uploadingPhoto = true;
      const videoElement = document.querySelector(
        "#my-video-chat-window video"
      );
      const canvas = document.createElement("canvas");

      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      canvas.getContext("2d").drawImage(videoElement, 0, 0);

      let dataUrl = canvas.toDataURL("image/png");

      var block = dataUrl.split(";");

      var contentType = block[0].split(":")[1];

      var realData = block[1].split(",")[1];

      let blob = self.b64toBlob(realData, contentType);

      let currentDate = new Date();
      let date = `${currentDate.getFullYear()}${
        currentDate.getMonth() + 1
      }${currentDate.getDate()}`;
      let time = `${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
      let dateTime = `${date}${time}`;

      let imageData = new FormData();
      imageData.append("file", blob, `${self.idEncargo}_${dateTime}`);
      imageData.append("request_from", "web");
      imageData.append("lat", this.position.latitude);
      imageData.append("lon", this.position.longitude);
      imageData.append("orientation", this.orientation);
      self.$axios
        .post("/uploadphoto", imageData, {
          headers: {
            Authorization: `Bearer ${self.accessToken}`,
          },
        })
        .then((response) => {
          this.uploadingPhoto = false;
          console.log("response", response);
          self.$socketClientWeb.emit("enviarFoto", {
            file: response.data.ruta,
            photoId: response.data.photoId,
            id_taller: self.encargo_pendiente.id_taller,
            id_perito: self.id_perito,
          });
        })
        .catch((err) => {
          this.uploadingPhoto = false;
          alert("Hubo un error al subir la foto");
          console.log("err", err);
        });
    });

    self.$socketClientWeb.on("zoomIn", function (data) {
      console.log("zoomIn", data);
    });

    self.$socketClientWeb.on("zoomOut", function (data) {
      console.log("zoomOut", data);
    });

    self.$socketClientWeb.on("acceptDniPhoto", function (data) {
      self.acceptedPhotos++;

      var block = data.photo.split(";");

      var contentType = block[0].split(":")[1];

      var realData = block[1].split(",")[1];

      let blob = self.b64toBlob(realData, contentType);

      let currentDate = new Date();
      let date = `${currentDate.getFullYear()}${
        currentDate.getMonth() + 1
      }${currentDate.getDate()}`;
      let time = `${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
      let dateTime = `${date}${time}`;

      let imageData = new FormData();
      imageData.append("file", blob, `${self.idEncargo}_${dateTime}`);
      imageData.append("request_from", "web");
      imageData.append("typePhoto", "dni");

      self.$axios
        .post("/uploadphoto", imageData, {
          headers: {
            Authorization: `Bearer ${self.accessToken}`,
          },
        })
        .then((response) => {
          console.log("response", response);
        })
        .catch((err) => {
          console.log("err", err);
        });

      // if(self.acceptedPhotos > 1) {
      //     self.webcam.stop();
      //     self.connectToRoom();
      //     document.getElementById('title-dni').classList.add('d-none');
      //     document.getElementById('take-photo').classList.add('d-none');
      //     document.getElementById('webcam').classList.add('d-none');
      //     document.getElementById('change-camera').classList.add('d-none');
      // }
    });
    self.$socketClientWeb.on("rejectDniPhoto", function (data) {
      console.log("rejectDniPhoto", data);
      // document.getElementById(`dni-photo-${data.positionPhoto}`).remove();
    });
  },
  methods: {
    searchDevices: function (mediaDevices) {
      self = this;
      mediaDevices.forEach((mediaDevice, i) => {
        if (mediaDevice.kind === "videoinput") {
          self.devices.push({
            name: mediaDevice.label || `Camara ${i}`,
            id: mediaDevice.deviceId,
          });
        }
      });
      this.helperText = "Seleccione una camara por favor";
    },
    detachTracks(track) {
      track.detach().forEach((detachedElement) => {
        detachedElement.remove();
      });
    },
    attachTracks(tracks, container) {
      tracks.forEach(function (track) {
        if (track) {
          container.appendChild(track.attach());
        }
      });
    },
    stopTracks(tracks) {
      tracks.forEach(function (track) {
        if (track) {
          track.stop();
        }
      });
    },
    buscarEncargos: function (numeroExpediente) {
      self = this;
      self.encargo_pendiente = {
        numero_expediente: "",
        matricula: "",
        marca: "",
        modelo: "",
        fecha_peritar: "",
        id_taller: "",
      };
      try {
        this.$axios
          .get(`/encargo/${numeroExpediente}`)
          .then(function (response) {
            if (response.data.codigo === 0) {
              self.encargo_pendiente = response.data.response.datos.encargo;
              self.idEncargo = response.data.response.datos.encargo.id_encargo;
              self.marca = response.data.response.datos.encargo.marca;
              self.modelo = response.data.response.datos.encargo.modelo;
              self.matricula = response.data.response.datos.encargo.matricula;
              self.id_taller = response.data.response.datos.encargo.id_taller;
              self.id_perito = response.data.response.datos.encargo.id_perito;
              self.getToken(self.accessToken, self.idEncargo, self.identity);

              let socketData={
                area: "Taller",
                id_compania: 8,
                id_encargo: self.idEncargo,
                modelo: self.matricula,
                matricula: self.marca,
                id_marca: self.modelo,
                taller: "mtaller",
                id_taller: self.id_taller,
                user_id: response.data.response.datos.encargo.id_perito,
              };
              self.$socketClientWeb.emit("esperaPerito", socketData);
              console.log("Datos enviados al socket: ",socketData);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    iniciarVideo: function () {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position);
          this.position.latitude = position.coords.latitude;
          this.position.longitude = position.coords.longitude;
          this.$socketClientWeb.emit("send_location", {
            id_perito: this.id_perito,
            lat: this.position.latitude,
            lon: this.position.longitude,
          });
        });
      }
      this.$socketClientWeb.emit("connecting_room", {
        id_perito: this.id_perito,
      });
      this.peritoIsConnected = false;
      this.on_play = true;
      this.on_play_text = "Video en curso";
      this.connectToRoom();
      // const webcamElement = document.getElementById('webcam');
      // const canvasElement = document.getElementById('canvas');
      // const takePhotoBtn = document.getElementById('take-photo');
      // const changeCameraBtn = document.getElementById('change-camera');

      // self.webcam = new Webcam(webcamElement, 'user', canvasElement);

      // navigator.permissions.query({name: 'camera'});
      // self.webcam.flip();
      // self.webcam.start()
      //     .then(result =>{
      //         document.getElementById('title-dni').classList.remove('d-none');
      //         takePhotoBtn.classList.remove('d-none');
      //         changeCameraBtn.classList.remove('d-none');
      //     })
      //     .catch(err => {
      //         console.log(err);
      //     });
    },
    getToken: function (token, room, identity) {
      self = this;
      this.$axios
        .post("/twilio_token", {
          token,
          room,
          identity,
        })
        .then(function (response) {
          self.twilioToken = response.data.token;
        });
    },
    connectToRoom: async function () {
      self = this;
      const { connect, createLocalVideoTrack } = require("twilio-video");
      this.fullScreen = true;
      this.helperText = "";
      this.show_message_client = false;

      const videoConstraints = {
        facingMode: { exact: "environment" },
        height:720,
        width:1280
      };

      const optionsTwilio = {
        name: this.idEncargo,
        // automaticSubscription: false,
        _useTwilioConnection: true,
        video: videoConstraints,
        audio: true,
        //logLevel: 'debug',
        bandwidthProfile: {
          video: {
            maxSubscriptionBitrate: 2500000,
            maxTracks: 0,
            trackSwitchOffMode: "predicted",
            dominantSpeakerPriority: "high",
            renderDimensions: {
              // high: {height:1080, width:1920},
              // standard: {height:720, width:1280},
              high: { height: 176, width: 144 },
              standard: { height: 176, width: 144 },
              low: { height: 176, width: 144 },
            },
          },
        },
        // maxAudioBitrate: 16000,
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        dominantSpeaker: true,
      };
      if (isSupported) {
        // self.tracksTwilio = await createLocalTracks();
        // const localVideoTrack = self.tracksTwilio.find(track => track.kind === 'video');
        console.log("Enviando token a twilio:",self.twilioToken);
        console.log("Options de Twilio:");
        console.log(optionsTwilio);        
        await connect(self.twilioToken, optionsTwilio).then(
          (room) => {
            // room.isRecording = false;
            console.log(`Successfully joined a Room: ${room}`);
            const videoChatWindow = document.getElementById(
              "my-video-chat-window"
            );

            // createLocalVideoTrack().then(track => {
            createLocalVideoTrack({
              name: `${room.sid}`,
              facingMode: { exact: "environment" },
            }).then((track) => {
              let video = track.attach();
              video.style.width = "100%";
              videoChatWindow.appendChild(video);
              if (this.isIOS) {
                // this.camera = this.devices.find(element => element.name == 'Camara trasera')
                this.changeCamera();
              }
            });

            // createLocalAudioTrack({name: `${room.sid}`}).then(track=>{
            //     console.log('acabo de creaar el track')
            //     console.log(track)
            // })

            // room.localParticipant.videoTracks.forEach(track => {
            //     console.log(track);
            //     let video = track.attach();
            //     video.style.width = "90vw";
            //     videoChatWindow.appendChild(video);
            // })
            // createLocalVideoTrack().then(function(localTrack) {
            //     room.localParticipant.addTrack(localTrack);
            // });
            room.localParticipant.audioTracks.forEach((publication) =>
              publication.track.enable()
            );

            room.participants.forEach((participant) => {
              participant.tracks.forEach((publication) => {
                if (publication.track) {
                  document
                    .getElementById("audio")
                    .appendChild(publication.track.attach());
                }
              });

              participant.on("trackSubscribed", (track) => {
                document.getElementById("audio").appendChild(track.attach());
              });
            });

            room.on("participantConnected", (participant) => {
              console.log(`A remote Participant connected: ${participant}`);
            });
            // room.localParticipant.publishTrack(self.tracksTwilio);
            self.inVideoPeritacion = true;
            self.roomTwilio = room;
            room.once("participantDisconnected", (participant) => {
              self.inVideoPeritacion = false;
              self.peritoIsConnected = false;
              console.log(
                `Participant "${participant.identity}" has disconnected from the Room`
              );
              this.disconected();
            });
            // if(this.isIOS) navigator.mediaDevices.enumerateDevices().then(this.searchDevices);

            console.log("La room del perito esta grabando? ");
            console.log(room.isRecording);
          },
          (error) => {
            console.error(`Unable to connect to Room: ${error.message}`);
            console.log(error);
            alert(`Unable to connect to Room: ${error.message}`);
          }
        );
      } else {
        alert("This browser is not supported by twilio");
      }
      // self.changeCamera()
    },
    changeCamera: async function () {
      try {
        const localParticipant = this.roomTwilio.localParticipant;

        this.helperText = "";
        try {
          let localAudioTrack = await createLocalAudioTrack();
          localParticipant.audioTracks.forEach((publication) => {
            publication.track.stop();
            publication.unpublish();
            this.detachTracks(publication.track);
          });

          localParticipant.publishTrack(localAudioTrack);
        } catch (error) {
          console.log();
        }
        if (window.stream) {
          window.stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
          this.detachTracks(publication.track);
        });
        setTimeout(() => {
          console.log("World!");
        }, 1000);
        createLocalVideoTrack({
          // deviceId: this.camera
          // ? {exact: this.camera}
          // : undefined
          facingMode: { exact: "environment" },
        })
          .then((localVideoTrack) => {
            localParticipant.publishTrack(localVideoTrack);

            const videoChatWindow = document.getElementById(
              "my-video-chat-window"
            );
            this.removeChilds(videoChatWindow);
            // this.attachTracks([localVideoTrack], videoChatWindow);
            let video = localVideoTrack.attach();
            video.style.width = "90vw";
            videoChatWindow.appendChild(video);
          })
          .catch((err) => {
            alert(err);
            throw err;
          });
      } catch (e) {
        alert("Error cambiar de camara: " + e.message);
      }

      // const facing = this.facingMode === 'environment' ? 'user' : 'environment';
      // const newFacingTrack = await createLocalVideoTrack({facingMode: facing});
      // const facingTrack = this.tracksTwilio.find(track => track.kind === 'video');
      // facingTrack.stop();
      // this.roomTwilio.localParticipant.unpublishTrack(facingTrack);
      // this.roomTwilio.localParticipant.publishTrack(newFacingTrack);
    },
    removeChilds(parent) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
      }
    },
    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    flipCamera: function () {
      self.webcam.flip();
      self.webcam.start();
    },
    takeImage: function () {
      self = this;
      // let picture = self.webcam.snap();
      const videoElement = document.querySelector(
        "#my-video-chat-window video"
      );
      const canvas = document.createElement("canvas");

      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      canvas.getContext("2d").drawImage(videoElement, 0, 0);

      let picture = canvas.toDataURL("image/png");

      self.countPhoto++;
      // const imageClient = new Image();
      // imageClient.src = picture;
      // imageClient.style.width = '200px';
      // imageClient.style.marginRight = '10px';
      // imageClient.setAttribute('data-image-position', self.countPhoto);
      // imageClient.setAttribute('id', `dni-photo-${self.countPhoto}`);
      // document.getElementById('photo-container').append(imageClient);
      self.$socketClientWeb.emit("sendDniPhoto", {
        image: picture,
        id_taller: self.encargo_pendiente.id_taller,
        countImage: self.countPhoto,
      });
    },
    disconected() {
      this.fullScreen = false;
      this.inVideoPeritacion = false;
      //this.on_play = false;
      this.on_play_text = "¡Vídeo Finalizado!";
      this.helperText = "";
      this.show_message_client = true;
      this.message_client = "Gracias por utilizar Avatar Expert";
      this.roomTwilio.localParticipant.tracks.forEach((publication) => {
        publication.track.stop();
        publication.unpublish();
        this.detachTracks(publication.track);
      });
      // this.roomTwilio.participants.forEach(remoteParticipant => {
      //     remoteParticipant.disconnect()
      // })
      this.roomTwilio.disconnect();

      const selector = document.getElementById("my-video-chat-window");
      while (selector.firstChild) {
        selector.removeChild(selector.lastChild);
      }
    },
    checkOrientation() {
      console.log(window.innerHeight, window.innerWidth);
      if (window.innerHeight < window.innerWidth) {
        //vertical
        this.orientation = "portrait";
        this.helperText = "Gire el móvil para iniciar la videoperitación";
        console.log(this.orientation);
      } else {
        //horizontal
        this.orientation = "landscape";
        this.helperText = "";
        // this.$socketClientWeb.emit('change_orientation', {orientation: 'landscape', id_perito : this.id_perito})
        console.log(this.orientation);
      }
    },
  },
};
</script>

<style scoped>
.my-video-chat-window video {
  width: 100%;
}
p.break {
  word-break: break-word;
}
.content_title {
  color: white;
  width: 100%;
  padding-left: 10px;
}
.content_title p {
  margin: 0;
}
.el-col {
  border-radius: 10px;
}
/* .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    } */
/* @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }
    @media (min-width: 1200px) {
        .container {
            width: 1170px;
        }
    } */
</style>

