<template>
    <div v-show="mostrar_iconos">        
        <!-- <img src="/img/reconnect.png" width="40" title="Reconectar" @click="enlazarStream()" class="pointer icon"> -->
        <img src="img/camera_100.png" width="40" title="Hacer foto" class="pointer icon" @click="clickFoto()">
        <img src="img/dni_picture.png" width="40" title="Hacer foto del dni" class="pointer icon" @click="tomarFotoDni()" v-if="this.showIconTakePictureDni">
<!--        <img src="img/flash_on_100.png" width="40" title="Encender flash" class="pointer"  @click="clickFlash()">-->
<!--        <img src="img/zoom_in.png" width="40" title="Zoom in" class="pointer"  @click="clickZoom(true)">-->
<!--        <img src="img/zoom_out.png" width="40" title="Zoom out" class="pointer"  @click="clickZoom(false)">-->

        <img src="img/stop_100.png" style="margin-left:40px;display:inline-block;" width="40" title="Parar grabación" @click="pararGrabacion()" class="pointer icon">
    </div>

</template>

<script>
    export default {
        name: "IconosControlComponent",
        props: ['showIconTakePictureDni'],
        data() {
            return {
                mostrar_iconos: false,
                detallesVho: this.$root.$children[0].$children[1],
            };
        },
        methods: {

            pararGrabacion: function () {
                this.$root.$socket.emit('pedirPararGrabacion', { id_encargo: this.detallesVho.encargo_seleccionado_idEncargo, id_taller: this.detallesVho.encargo_seleccionado_id_taller});
                this.detallesVho.desconectar();
            },
            clickFlash: function () {
                this.$root.$socket.emit('pedirFlash', { id_encargo: this.detallesVho.encargo_seleccionado_idEncargo, id_taller: this.detallesVho.encargo_seleccionado_id_taller});
            },
            clickFoto: function () {
                this.$root.$socket.emit('pedirFoto', { id_encargo: this.detallesVho.encargo_seleccionado_idEncargo, id_taller: this.detallesVho.encargo_seleccionado_id_taller});
            },
            clickZoom: function (isTypeIn) {
                if (isTypeIn === true) {
                    this.$root.$socket.emit('pedirZoomIn', { id_encargo: this.detallesVho.encargo_seleccionado_idEncargo, id_taller: this.detallesVho.encargo_seleccionado_id_taller});
                } else {
                    this.$root.$socket.emit('pedirZoomOut', { id_encargo: this.detallesVho.encargo_seleccionado_idEncargo, id_taller: this.detallesVho.encargo_seleccionado_id_taller});
                }

            },
            mostrarIconos: function () {
                this.mostrar_iconos = true;
            },
            ocultarIconos: function () {
                this.mostrar_iconos = false;
            },

            tomarFotoDni: function () {
                this.$root.$socket.emit('sendTomarFotoDni', { id_encargo: this.detallesVho.encargo_seleccionado_idEncargo, id_taller: this.detallesVho.encargo_seleccionado_id_taller});
            },
            enlazarStream: function (e) {
                const detallesVho = this.$root.$children[0].$children[1];
        
                // detallesVho.mostrarInfoEncargo(this.encargoOnline);
                detallesVho.reconectar()

                // detallesVho.encargo_seleccionado_marca = this.encargoOnline.marca;
            }

        }

    }
</script>

<style scoped>
img.icon{
    width: 20%;
}
</style>
