<template>
    <div class="container mt-3">
        <!-- <el-button @click="getOrders">
            Get orders
        </el-button> -->
        <div class="row">
            <div class="col-lg-3">
                <h3>Encargos</h3>
                <div v-for="order in orders" :key="order.id">
                    <order-card
                        :ref="order.id"
                        :order='order'
                        @initVideo='startVideo'
                        @stopVideo='disconnect'
                        :isZaragoza='isZaragoza'
                        :orientation='orientation'
                    />
                </div>
            </div>
            <div class="col-lg-9 border video">
                <h1>
                    {{ helperText }}
                </h1>
                <div id="my-video-chat-window"></div>
                <div id="audio"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Loading } from 'element-ui'
import OrderCard from './OrderCard.vue'
import { connect, createLocalVideoTrack, isSupported, createLocalAudioTrack } from 'twilio-video'
    export default {
        components: {
            OrderCard,
        },
        props: {
            company: {
                type: String,
            },
        },
        data() {
            return {
                orders: [],
                room: null,
                orientation: 'portrait',
                isZaragoza : false,
                helperText : '',
                playing : false,
            }
        },
        created () {
            this.getOrders();
        },
        mounted () {
            if(this.company == 'Zaragoza'){
                this.isZaragoza = true
                // this.checkOrientation();
                this.helperText = 'Gire el móvil para iniciar la videoperitación'
                window.addEventListener("orientationchange", this.checkOrientation)
            }
        },
        methods: {
            getOrders() {
                let loading = Loading.service({
                    lock: true,
                    text: 'Cargando...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                let token = localStorage.getItem('token')
                axios.get(`/api/encargos`, {
                    headers: {
                        'Authorization' : `Bearer ${token}`
                    }
                })
                .then(res => {
                    this.orders = res.data.orders
                    loading.close()
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            startVideo(order, onError){
                if(this.playing){
                    alert('Ya estas en una peritación')
                    onError()
                    return
                }
                this.playing = true
                let loading = Loading.service({
                    lock: true,
                    text: 'Iniciando video...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                let token = localStorage.getItem('token') 
                let data = {
                    area: 'Perito',
                    token: token,
                    room: order.id,
                    identity: localStorage.getItem('nombre')
                }
                let headers = {
                    'Authorization' : `Bearer ${token}`
                }
                axios.post('twilio_token', data, {headers})
                .then(res => {
                    loading.close()
                    if (res.data !== 1 && res.data.codigo == 0 && res.data.token !== 1) {
                        this.connectRoom(order, res.data.token)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })

            },
            async connectRoom(order, token){
                const videoConstraints = {
                    facingMode: { exact: "environment" }
                };
                let connectOptions = {
                    name:  order.id,
                    audio: true,
                    video: videoConstraints,
                    _useTwilioConnection: true,
                    bandwidthProfile: {
                        video: {
                            maxSubscriptionBitrate: 2500000,
                            maxTracks: 0,
                            trackSwitchOffMode: 'predicted',
                            dominantSpeakerPriority: 'high',
                            renderDimensions: {
                                // high: {height:1080, width:1920},
                                // standard: {height:720, width:1280},
                                high: {height:176, width:144},
                                standard: {height:176, width:144},
                                low: {height:176, width:144}
                            }
                        }
                    },
                    dominantSpeaker: true,
                    // maxAudioBitrate: 16000,
                    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
                }
                if(!isSupported){
                    alert('This browser is not supported by twilio')
                    return
                }
                try {
                    
                    this.room = await connect(token, connectOptions)
                    console.log(`Successfully joined a Room: ${this.room}`);
                    const videoChatWindow = document.getElementById('my-video-chat-window');
                    axios.get('/room_info', {
                        params: {
                            room_sid: this.room.sid,
                            room_name: this.room.name,
                            participant_sid_perito: this.room.localParticipant.sid
                        }, headers: {
                            Authorization: "Bearer " + localStorage.getItem('token')
                        }
                    }).then()
                    .catch(err=>{
                        console.log(err)
                    })
            

                    createLocalVideoTrack({facingMode: {exact: "environment"}})
                    .then(track => {
                        let video = track.attach();
                        video.style.width = "100%";
                        videoChatWindow.appendChild(video);
                        if(this.isIOS){
                            this.changeCamera()
                        }
                    });

                    this.room.localParticipant.audioTracks.forEach(
                        publication => publication.track.enable()
                    );

                    this.room.participants.forEach(participant => {
                        participant.tracks.forEach(publication => {
                            if (publication.track) {
                                document.getElementById('audio').appendChild(publication.track.attach());
                            }
                        });

                        participant.on('trackSubscribed', track => {
                            document.getElementById("audio").appendChild(track.attach());
                        });
                    });

                    console.log("La room del perito esta grabando? ")
                    console.log(this.room.isRecording)

                } catch (error) {
                    console.log(error)
                     alert('Hubo un error al conectarse al iniciar el video')
                }
                
            },
            changeCamera: async function () {
                try {
                    const localParticipant = this.room.localParticipant;
                    try {
                        let localAudioTrack = await createLocalAudioTrack();
                        localParticipant.audioTracks.forEach(publication => {
                            publication.track.stop();
                            publication.unpublish();
                            this.detachTracks(publication.track);
                        });
                        
                        localParticipant.publishTrack(localAudioTrack);
                        
                    } catch (error) {
                        console.log()    
                    }
                    if (window.stream) {
                        window.stream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                    localParticipant.videoTracks.forEach(publication => {
                        publication.track.stop();
                        publication.unpublish();
                        this.detachTracks(publication.track);
                    });
                    setTimeout(() => { console.log("World!"); }, 1000);
                    createLocalVideoTrack(
                        {
                            
                            // deviceId: this.camera 
                            // ? {exact: this.camera} 
                            // : undefined
                            facingMode: {exact: "environment"}
                        }
                    ).then(localVideoTrack => {
                        
                        
                        
                        localParticipant.publishTrack(localVideoTrack);
                    
                        const videoChatWindow = document.getElementById('my-video-chat-window');
                        this.removeChilds(videoChatWindow)
                        // this.attachTracks([localVideoTrack], videoChatWindow);
                        let video = localVideoTrack.attach();
                        video.style.width = "90vw";
                        videoChatWindow.appendChild(video);
                        
                    }).catch((err) => {
                        alert(err)
                        throw err
                    });
                        
                    
                    
                } catch (e) {
                    alert('Error cambiar de camara: ' + e.message)
                }

                // const facing = this.facingMode === 'environment' ? 'user' : 'environment';
                // const newFacingTrack = await createLocalVideoTrack({facingMode: facing});
                // const facingTrack = this.tracksTwilio.find(track => track.kind === 'video');
                // facingTrack.stop();
                // this.roomTwilio.localParticipant.unpublishTrack(facingTrack);
                // this.roomTwilio.localParticipant.publishTrack(newFacingTrack);
            },
            detachTracks(track) {
                
                track.detach().forEach((detachedElement) => {
                    detachedElement.remove();
                });
                
            },
            disconnect(order){
                console.log('disconect')
                try {
                    this.playing = false
                    this.room.localParticipant.tracks.forEach(publication => {
                        publication.track.stop();
                        publication.unpublish();
                        this.detachTracks(publication.track);
                    });
                    // this.roomTwilio.participants.forEach(remoteParticipant => {
                    //     remoteParticipant.disconnect()
                    // })
                    this.room.disconnect();
                    const selector = document.getElementById('my-video-chat-window');
                    while (selector.firstChild) {
                        selector.removeChild(selector.lastChild);
                    }
                    this.closeOrder(order)
                } catch (error) {
                    console.log(error)
                    alert('Posiblemente no este emitiendo ningun video')
                }
                
            },
            closeOrder(order){
                let loading = Loading.service({
                    lock: true,
                    text: 'Iniciando video...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                axios.post(`/cerrar-encargo/${order.id}`)
                .then(res => {
                    loading.close()
                    alert(res.data.message);
                    console.log(res)
                })
                .catch(err => {
                    loading.close()
                    alert(res.data.message)
                    console.error(err); 
                })
            },
            checkOrientation(){
                console.log(window.innerHeight, window.innerWidth)
                if(window.innerHeight < window.innerWidth){
                    //vertical
                    this.orientation = 'portrait'
                    this.helperText = 'Gire el móvil para iniciar la videoperitación'
                    console.log(this.orientation)
                }
                else{
                    //horizontal
                    this.orientation = 'landscape'
                    this.helperText = ''
                    // this.$socketClientWeb.emit('change_orientation', {orientation: 'landscape', id_perito : this.id_perito})
                    console.log(this.orientation)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.row{
    width: 100%;
}
.video{
    height: 60vh;
}
</style>