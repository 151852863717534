<template>
    <div>
        <el-card>
            <div>
                <b>Matricula:</b> <span>{{ order.matricula }}</span>
            </div>
            <div>
                <span>{{ order.modelo }}/{{ order.id_marca }}</span>
            </div>
            <div>
                <el-button type='success' icon='fa fa-play' circle size='mini'  @click="initVideo" :disabled="disabled"/>
                <el-button type='danger' icon='fa fa-stop' circle size='mini' @click="stopVideo" :disabled="!playing"/>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        props: {
            order: {
                type: Object
            },
            start:{
                type: Boolean,
                default:true,
            },
            isZaragoza:{
                type: Boolean,
                default: false,
            },
            orientation:{
                type: String,
                default: 'portrait',
            }
        },
        data() {
            return {
                playing : false,
                stoping : false,
            }
        },
        computed: {
            disabled() {
                if(this.isZaragoza && this.orientation == 'landscape'){
                    return false
                }
                else if (this.isZaragoza && this.orientation == 'portrait'){
                    return true
                }
                else if(this.playing){
                    return true
                }
                else if(this.stoping){
                    return false
                }
            }
        },
        methods: {
            initVideo() {
                this.playing = true
                this.stoping = false
                console.log('iniciando video')
                this.$emit('initVideo', this.order, this.setValues)
            },
            stopVideo(){
                this.playing = false
                this.stoping = true
                console.log('Stop video')
                this.$emit('stopVideo', this.order)
            },
            setValues(){
                this.playing = false
                this.stoping = true
            }
        },
    }
</script>

<style lang="scss" scoped>

// .pointer{
//     cursor: pointer;
// }

</style>