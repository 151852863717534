<template>
    <div>
        <button class="btn btn-primary my-3" @click="visible=true">
            Subir imagenes
        </button>
        <el-dialog
            :title="`Subir imagenes`"
            :visible.sync="visible"
            width="50%"
            :close-on-click-modal='false'
            @closed='closeDialog'
        >
            <div class="row">
                <div class="col text-center">
                    <el-upload
                        action="/uploadphoto"
                        :data="extraData"
                        :headers='header'
                        list-type="picture-card"
                        class="upload-demo"
                        :on-success='onSuccess'
                        multiple
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            orderid: {
                type: String,
                required: true
            },
            csrf :{
                required: false
            }
        },
        data() {
            return {
                visible: false,
                uploaded: false,
            }
        },
        computed: {
            header() {
                let token = localStorage.getItem('token')
                return {
                    'Authorization': `Bearer ${token}`
                }
            },
            extraData() {
                return {
                    '_token': this.csrf,
                    'typePhoto' : 'externa',
                    'orderId' : this.orderid,
                    'lat' : null,
                    'lon' : null,
                }
            }
        },
        methods: {
            closeDialog() {
                this.visible = false
                this.$emit('uploaded', this.uploaded)
            },
            onSuccess(){
                this.uploaded = true
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>