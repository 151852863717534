<template>
    <div>
        <el-button @click="visible = true" >Conocer ubicación</el-button>
        <el-dialog
            title="Ubicación del usuario"
            :visible.sync="visible"
            width="40%"
        >
           <google-map-loader
                class="map"      
                :apiKey="apiKey"
                :mapConfig="mapConfig"
            />

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="visible = false">Ok</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import GoogleMapLoader from './GoogleMapsLoader.vue'
    export default {
        components: {
            GoogleMapLoader,
        },
        props: {
            lat: {
                type: Number,
            },
            lon:{
                type: Number,
            }
        },
        computed: {
            mapConfig(){
                return{
                    center: {lat: this.lat, lng: this.lon},
                    zoom: 10
                }
            }
        },
        data() {
            return {
                apiKey: 'AIzaSyB6sKGpA6jpHJBVVR1okiiM2oz7dherI5o',
                visible: false,
                location: null
            }
        },
    }
</script>

<style lang="scss" scoped>
.el-button{
    background-color: #007a8b;
    color: white;
    border-color: #007a8b;
}
</style>