<template>
    <li>
        <div class="nombre_taller">{{encargoD.taller}}</div>
        <div class="detalles">{{encargoD.marca}} {{encargoD.modelo}} - {{encargoD.matricula}}</div>
    </li>
</template>

<script>
    export default {
        name: "EncargoComponent",
        props: ['encargoD'],
    }
</script>

<style scoped>

</style>
