<template>
    <ul id="encargos_online" v-cloak>
        <encargo-online-component
            v-for="(encargoOnline, index) in encargos_online"
            :key="index"
            :encargoOnline="encargoOnline"></encargo-online-component>
    </ul>
</template>

<script>
    import EncargoOnlineComponent from "./EncargoOnlineComponent";
    export default {
        name: "EncargosOnlineComponent",
        components: {EncargoOnlineComponent},
        props: {
            user_id: {
                required:true
            },
        },
        data() {
            return {
                encargos_online: []
            };

        },
        mounted() {
            this.$socket.on('actualizarEncargosPerito', (data) => {
                let arr =  JSON.parse(data)
                arr = arr.filter(el => el.id_perito == this.user_id) 
                if(arr.length > 0){
                    this.$message({
                        showClose: true,
                        message: 'Se ha conectado un usuario, presiona en el encargo para conectar el.',
                        type: 'success'
                    });
                }
                
                this.encargos_online = arr;
            });
        }
    }
</script>

<style scoped>

</style>
