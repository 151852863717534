<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <ckeditor
                    v-model="editorData"
                    :config="editorConfig"
                ></ckeditor>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <button class="btn btn-primary" @click="saveComments">
                    Guardar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        orderid: {
            required: true,
            type: String
        },
        logo: {
            required: true,
            type: String
        },
        order: {
            type: Object
        },
        routeback: {
            type: String
        }
    },
    data() {
        return {
            editorData:
                this.order.html_comments != ""
                    ? `${this.order.html_comments}`
                    : `
                    <table style="font-family: arial, sans-serif; border-collapse: collapse;width: 100%;">
                        <tbody>
                            <tr  >
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" colspan="6">
                                    <b>
                                        MKDautomotive Group. www.mkdautomotive.com info@mkdrecambios.com Telf (+34) 91 159 33 30
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;"  colspan="2">
                                    <img src="${this.logo}" width="150" height="100"></img>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;"  colspan="4">
                                    <h1>INFORME TÉCNICO PERICIAL DE AUTOMÓVIL</h1>
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    <b>Ref. PTR:</b> 
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Ref.
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    <b>FECHA:</b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Fecha
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    <b>REF</b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte REF
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" colspan="2">
                                    <b>Gabinete Pericial Nacional</b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" colspan="2">
                                    <b>Grupo MKD Automotive Solutions</b>
                                </td >
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" colspan="2">
                                    <b>
                                        Peritación de Chapa
                                    </b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>

                    <table style="font-family: arial, sans-serif; border-collapse: collapse;width: 100%;">
                        <tbody>
                            <tr >
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;"   colspan="6">
                                    <b>
                                        DATOS VEHÍCULO
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Matrícula
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    ${this.order.matricula}
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Fecha de inspección
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    ${this.order.fecha_hora_cita}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Marca
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    ${this.order.id_marca}
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Km. vehiculo
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Km.
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Modelo
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    ${this.order.modelo}
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Color vehiculo
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Color del Vehiculo
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Versión
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Version
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        FºMatriculación
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte F Marticulación
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        VIN
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte VIN
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Importe Daños Ext.
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Importe de daños Ext.
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Combustible
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Tipo de combustible
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Importe Daños Int.
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Importe de daños Int.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                
                    <table style="font-family: arial, sans-serif; border-collapse: collapse;width: 100%;">
                        <tbody>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" colspan="6">
                                    <b>
                                        LUGAR DE PERITACIÓN
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Nombre
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Nombre
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Localidad
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Localidad
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Teléfono
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Teléfono
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Dirección
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Direccion.
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Provincia
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Provincia
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Persona Contacto Taller
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Persona de Contacto
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>

                    <table style="font-family: arial, sans-serif; border-collapse: collapse;width: 100%;">
                        <tbody>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;" rowspan="5">
                                    <p><b>La compañía solicita peritar:</b></p>
                                    <p>${this.order.averia1}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <table style="font-family: arial, sans-serif; border-collapse: collapse;width: 100%; page-break-before: always;">
                        <tbody>
                            <tr  >
                                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;" colspan="8">
                                    <b>
                                        INFORME FINAL
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Fº Gestión
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Fº Gestion
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Estado vehículo
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;" colspan="3">
                                    Inserte Estado del Vehículo
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>Val. aprox. rep. BI</b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Valoracion Aproximada
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Fº Prox. Gestión
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Fecha de la proxima gestion
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Fº Prev. Fin Rep.
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    Inserte Fº Prev.
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>
                                        Núm Siniestros
                                    </b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    #
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;"  >
                                    <b>Siniestro Total</b>
                                </td>
                                <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
                                    No
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br class="pagebreak">
                    <p>Agregar nota aqui</p>
                    <br>
                    <table style="width: 100%;" class="tablita">
                        <tbody>
                            <tr>
                                <td style="text-align: right;"> 
                                    <img src="${this.logo}" width="150" height="100"></img>
                                </td>
                            <tr>
                            <tr>
                                <td style="text-align: right;" class="pagebreak">
                                    Grupo MKD Automotive Solutions
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p id="here" style="page-break-after: always; color:blue" class="pagebreak text-center">Hoja de control</p>
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td>
                                    HOJA DE CONTROL: ${this.orderid}
                                </td>
                                <td>
                                    <b>INSERTE NR</b>
                                </td>
                                <td>
                                    ${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td>
                                    REFERENCIA <span>INSERTE REFERENCIA</span>
                                </td>
                                <td colspan="2">
                                    <b>AUDATRANSFER: <span>INSERTE AUDATRANSFER</span></b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td>
                                    FABRICANTE
                                </td>
                                <td >
                                    ${this.order.id_marca}
                                </td>
                                <td colspan="2">
                                    ${this.order.modelo}
                                </td>
                                <td colspan="2">
                                    INSERTE SUBMODELO
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    NR CHASIS
                                </td>
                                <td>
                                    INSERTE NR CHASIS
                                </td>
                                <td>
                                    Matric.
                                </td>
                                <td>
                                    ${this.order.matricula}
                                </td>
                                <td>
                                    CÓDIGO TIPO
                                </td>
                                <td>
                                    INSERTE CODIGO 
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style="width: 100%">
                        <tr>
                            <td>
                                <b>CÓDIGO ABONADO</b>
                            </td>
                            <td>
                                Inserte codigo
                            </td>
                            <td>
                                Terminal
                            </td>
                            <td>
                                INSERTE TERMINAL
                            </td>
                            <td>
                                CLASS REL.
                            </td>
                            <td>
                                INSERTE
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <b>
                                    FECHA TRANSMISIÓN
                                </b>
                            </td>
                            <td colspan="4">
                                INSERTE FECHA DE TRANSMISIÓN
                            </td>
                        </tr>
                    </table>
                    <br>

                    <table style="width:100%">
                        <tbody>
                            <tr>
                                <td>
                                    <b>
                                        CÓDIGOS CARACTERÍSTICAS EQUIPO
                                    </b>
                                    <td>
                                        (IDENTIFICACIÓN AUDAVIN)
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Copie y pegue estas celdas
                                </td>
                                <td>
                                    Para agregar mas caracteristicas
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>

                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>CARACTERÍSTICAS EQUIPO NO MARCADAS QUE PODRÍAN INFLUIR EN EL RESULTADO</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Copie y pegue 
                                </td>
                                <td>
                                    esta fila de ser necesario
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style="width: 100%;">

                        <tbody>

                            <tr>
                                <td colspan="2">
                                    <b>POSICIONES DOCUMENTO BASE</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Copie y pegue esta fila
                                </td>
                                <td>
                                    de ser necesario
                                </td>
                            </tr>

                        </tbody>

                    </table>

                    <br>
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td colspan="3"><b>PINTURA</b></td>
                            </tr>
                            <tr>
                                <td>
                                <b>TIPO</b> 
                                </td>
                                <td colspan="2">inserte tipo</td>
                            </tr>
                            <tr>
                                <td>NIVELES</td>
                                <td>INSERTE DAÑO</td>
                                <td>INSERTE NIVEL (copiar y pegar las filas de ser necesario)</td>
                            </tr>
                            
                        </tbody>
                    </table>

                    <br>
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>
                                        PRECIOS HORA
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    copiar y pegar la fila
                                </td>
                                <td>
                                    de ser necesario
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>
                                        CÓDIGOS OPCIONALES
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Copiar y pegar la fila
                                </td>
                                <td>
                                    de ser necesario
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td>
                                    <b>
                                        FECHA TARIFA RECAMBIOS
                                    </b>
                                </td>
                                <td>
                                    INSERTE FECHA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>TOTAL VALORADO</b>
                                </td>
                                <td>
                                    INSERTE TOTAL VALORADO
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>

                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>NÚMERO POSICIONES</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    
                                    TOTAL INTRODUCIDAS
                            
                                </td>
                                <td>
                                    INSERTE TOTAL
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    MODIFICADAS POR USUARIO
                                </td>
                                <td>
                                    INSERTE TOTAL MODIFICADAS
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>


                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>POSICIONES INTRODUCIDAS</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    copiar y pegar esta fila 
                            
                                </td>
                                <td>
                                    de ser necesario
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <br>
                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>DATOS GENERALES</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    REFERENCIA
                                </td>
                                <td>
                                    INSERTE REFERENCIA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FECHA DE SINIESTRO
                                </td>
                                <td>
                                    INSERTE FECHA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    COMPROMISO
                                </td>
                                <td>
                                    INSERTE COMPROMISO
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <br>
                    <br>
            
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <b>DATOS VEHÍCULO</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FABRICANTE 
                                </td>
                                <td>
                                    ${this.order.id_marca}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    MODELO/ TIPO 
                                </td>
                                <td>
                                    INSERTE MODELO / TIPO
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    MATRICULA 
                                </td>
                                <td>
                                    ${this.order.matricula}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    NÚMERO CHASIS 
                                </td>
                                <td>
                                    INSERTE NÚMERO CHASIS
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    FECHA MATRICULACION 
                                </td>
                                <td>
                                    INSERTE FECHA
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    SUBTOTAL
                                </td>
                                <td>
                                    Euros
                                </td>
                                <td style="text-align: right;">
                                    INSERTE CANTIDAD
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2">
                                    SUMA TOTAL
                                </td>
                                <td>
                                    21% IVA
                                </td>
                                <td>
                                Euros
                                </td>
                                <td style="text-align: right;">
                                    INSERTE CANTIDAD
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CON IVA
                                </td>
                                <td>
                                    Euros
                                </td>
                                <td style="text-align: right;">
                                    INSERTE CANTIDAD 
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    FRANQUICIA 
                                </td>
                                <td>
                                    Euros
                                </td>
                                <td style="text-align: right;">
                                    INSERTE CANTIDAD 
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    SUMA TOTAL 
                                </td>
                                <td>
                                    Euros
                                </td>
                                <td style="text-align: right;">
                                    INSERTE CANTIDAD 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td>
                                    <b>
                                        REFERENCIA
                                    </b>
                                </td>
                                <td>
                                    INSERTE REFERENCIA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    COMPROMISO
                                </td>
                                <td>
                                    SI/NO
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td>
                                    FABRICANTE
                                </td>
                                <td >
                                    ${this.order.id_marca}
                                </td>
                                <td colspan="2">
                                    ${this.order.modelo}
                                </td>
                                <td colspan="2">
                                    INSERTE SUBMODELO
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    NR CHASIS
                                </td>
                                <td>
                                    INSERTE NR CHASIS
                                </td>
                                <td>
                                    Matric.
                                </td>
                                <td>
                                    ${this.order.matricula}
                                </td>
                                <td>
                                    CÓDIGO TIPO
                                </td>
                                <td>
                                    INSERTE CODIGO 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                    <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <b>PIEZAS SUSTITUIDAS</b>
                                    </td>
                                    <td>
                                        FECHA TARIFA
                                    </td>
                                    <td>
                                        INSERTE FECHA
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td>POS.D.B</td>
                                    <td>DESCRIPCIÓN</td>
                                    <td>REFERENCIA</td>
                                    <td>CANTIDAD</td>
                                    <td>DTO</td>
                                    <td>PRECIO</td>
                                </tr>
                                <tr>
                                    <td>INSERTE POS.D.B</td>
                                    <td>INSERTE DESCRIPCIÓN</td>
                                    <td>COPIE Y PEGUE ESTA FILA</td>
                                    <td>DE SER NECESARIO</td>
                                    <td>INSERTE DTO</td>
                                    <td style="text-align: right;">INSERTE PRECIO</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="5">TOTAL PIEZAS</td>
                                <td style="text-align: right;">INSERTE PRECIO</td>
                            </tfoot>
                        </table>
                        <hr>
                        <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>M.I CHAPA/MECÁNICA</b>
                                    </td>
                                    <td>
                                        X UT = Y HORA
                                    </td>
                                    <td colspan="2">
                                        PRECIO = X Euros/HORA
                                    </td>
                                </tr>
                                <tr>
                                    <td>NR. OPERACIÓN POS D.B</td>
                                    <td>DESGLOCE MANO DE OBRA</td>
                                    <td>UT</td>
                                    <td>IMPORTE TRABAJO</td>
                                </tr>
                                <tr>
                                    <td>Copie y pegue</td>
                                    <td>de </td>
                                    <td>ser</td>
                                    <td style="text-align: right;">necesario</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="2">TOTAL M.O. CH/MEC</td>
                                <td>UT</td>
                                <td style="text-align: right;">INSERTE PRECIO</td>
                            </tfoot>
                        </table>
                        <hr>

                        <table style="width: 100%">
                            <tbody>
                                <tr>
                                    <td colspan="6">
                                        <b>PINTURA</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POS. D.B</td>
                                    <td>DESCRIPCIÓN</td>
                                    <td>NV</td>
                                    <td>DTO</td>
                                    <td>UT</td>
                                    <td>MATERIAL</td>
                                </tr>
                                <tr>
                                    <td>INSERTE POS. D.B</td>
                                    <td>INSERTE DESCRIPCIÓN</td>
                                    <td>INSERTE NV</td>
                                    <td>INSERTE DTO</td>
                                    <td>INSERTE UT</td>
                                    <td>INSERTE MATERIAL (copie y pegue de ser necesario)</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        <br>
                        <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td rowspan="2">
                                        RESUMEN M.O PINTURA TIEMPO BASE X UT = Y H
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align: right;">
                                        PRECIO = X Euros/HORA
                                    </td>
                                </tr>
                                <tr>
                                    <td>DESCRIPCIÓN</td>
                                    <td style="text-align: right;">UT</td>
                                    <td style="text-align: right;">IMPORTE</td>
                                </tr>
                                <tr>
                                    <td>
                                        TIEMPO M.O
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE UT
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE IMPORTE
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TIEMPO REPARACION
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE UT
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE IMPORTE
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TIEMPO M.O PINTURA
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE UT
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE IMPORTE
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        RESUMEN MATERIALES PINTURA
                                    </td>
                                    <td style="text-align: right;">
                                        PRECIO
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        MATERIALES
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE PRECIO
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        CONSTANTE MATERIAL
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE PRECIO
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        TOTAL MATERIALES
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE PRECIO
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <hr>
                        
                        <table style="width:100%">
                            <tbody>
                               <tr style="border-bottom: 5px;">
                                    <td><b>RESUMEN FINAL</b></td>
                                    <td>
                                        Euros
                                    </td>
                                    <td style="text-align: right;">
                                        Euros
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        <table style="width:100%">
                            <tbody>
                                <tr>
                                    <td>
                                        REPUESTOS
                                    </td>
                                    <td>
                                        INSERTE VALORACION DE REPUESTOS
                                    </td>
                                    <td style="text-align: right;">
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TOTAL MATERIALES
                                    </td>
                                    <td style="text-align: right;" colspan="2">
                                        INSERTE TOTAL 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>

                        <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td>
                                        M.O. CHAPA/MACÁNICA
                                    </td>
                                    <td>
                                        TIEMPO BASE
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        INSERTE CONCEPTO
                                    </td>
                                    <td>
                                        Y UT X EUR / H (copiar y pegar de ser necesario)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TOTAL M.O CHAPA/MEC'ANICA
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE TOTAL
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                         <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        PINTURA
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        IMPORTE M.O
                                    </td>
                                    <td>
                                        INSERTE IMPORTE
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        MATERIAL DE PINTURA
                                    </td>
                                    <td>
                                        INSERTE IMPORTE
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TOTAL M.O CHAPA/MEC'ANICA
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE TOTAL
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr>
                        <table style="width: 100%;">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        SUBTOTAL
                                    </td>
                                    <td>
                                        Euros
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE CANTIDAD
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2">
                                        SUMA TOTAL
                                    </td>
                                    <td>
                                        21% IVA
                                    </td>
                                    <td>
                                    Euros
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE CANTIDAD
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        CON IVA
                                    </td>
                                    <td>
                                        Euros
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE CANTIDAD 
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        FRANQUICIA 
                                    </td>
                                    <td>
                                        Euros
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE CANTIDAD 
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        SUMA TOTAL 
                                    </td>
                                    <td>
                                        Euros
                                    </td>
                                    <td style="text-align: right;">
                                        INSERTE CANTIDAD 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>

                `,

            editorConfig: {
                // The configuration of the editor.
            }
        };
    },
    methods: {
        saveComments() {
            this.pagebreak = 'pagebreak'
            let data = {
                comments: this.editorData
            };
            axios
                .put(`/comment/${this.orderid}`, data)
                .then(res => {
                    window.location.href = `${this.routeback}`;
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
};
</script>

<style scoped>

@media print {
    .pagebreak {
        visibility: hidden;
        page-break-after: always;
    }
}

</style>
